import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoading: {
    fetchUser: false,
    updateUser: false,
    updatePassword: false
  },
  avatar: '',
  userName: '',
  email: '',
  authority: [],
  userData: {},
}

export const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    // Fetch User
    fetchUserStart: (state) => {
      state.isLoading.fetchUser = true
    },
    fetchUserSuccess: (state, { payload }) => {
      state.isLoading.fetchUser = false
      state.userData = payload
    },
    fetchUserFail: (state) => {
      state.isLoading.fetchUser = false
    },

    // Update User
    updateUserStart: (state) => {
      state.isLoading.updateUser = true
    },
    updateUserSuccess: (state, { payload }) => {
      state.isLoading.updateUser = false
      state.userData = payload
    },
    updateUserFail: (state) => {
      state.isLoading.updateUser = false
    },

    // Update password
    updatePasswordStart: (state) => {
      state.isLoading.updatePassword = true
    },
    updatePasswordSuccess: (state) => {
      state.isLoading.updatePassword = false
    },
    updatePasswordFail: (state) => {
      state.isLoading.updatePassword = false
    },

    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
  },
})

export const {
  setUser,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  updatePasswordStart,
  updatePasswordSuccess,
  updatePasswordFail
} = userSlice.actions

export default userSlice.reducer
