import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import {
  getAllTeamsStart,
  getAllTeamsSuccess,
  getAllTeamsFail,
  getTeamSuccess,
  getTeamFail,
  getTeamStart,
  getTeamMembersStart,
  getTeamMembersSuccess,
  getTeamMembersFail,
} from 'store/reducer/teamManagement'
import { openNotification } from 'utils/showToast'

// Get All Users
export function* getAllTeamsSaga(action) {
  yield put(getAllTeamsStart())
  const { onSuccess, startIndex, qString } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/teams?startIndex=${startIndex}&${qString}`,
    successHandler: yield function* (response) {
      yield put(getAllTeamsSuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(getAllTeamsFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getTeamSaga(action) {
  yield put(getTeamStart())
  const { onSuccess, onFail, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/teams/${id}`,
    successHandler: yield function* (response) {
      yield put(getTeamSuccess(response.data.items[0]))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(getTeamFail(response))
      onFail?.(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getTeamMembersSaga(action) {
  yield put(getTeamMembersStart())
  const { id, startIndex, qString } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/teams/${id}/members?startIndex=${startIndex}&${qString}`,
    successHandler: yield function* (response) {
      yield put(getTeamMembersSuccess(response.data))
    },
    failHandler: yield function* (response) {
      yield put(getTeamMembersFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}
