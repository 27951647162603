import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: {
        getAllContent: false,
        updateContent: false
    },
    allContent:[]
}
export const contentManagementSlice = createSlice({
    name: 'contenttManagement',
    initialState,
    reducers: {
          getAllContentStart: (state) => {
            state.isLoading.getAllContent  = true
          },
      
          getAllContentSuccess: (state, { payload }) => {
            state.isLoading.getAllContent = false
            state.allContent = payload
          },
          getAllContentFail: (state) => {
            state.isLoading.getAllContent = false
          },
          UpdateContentStart: (state) => {
            state.isLoading.updateContent  = true
          },
          UpdateContentSuccess: (state, { payload }) => {
            state.isLoading.updateContent = false
          },
          UpdateContentFail: (state) => {
            state.isLoading.updateContent = false
          },
    
    }
})
export default contentManagementSlice.reducer
export const { 
    getAllContentFail,
    getAllContentStart,
    getAllContentSuccess,
    UpdateContentFail,
    UpdateContentSuccess,
    UpdateContentStart
   
 } = contentManagementSlice.actions