const appConfig = {
  apiPrefix: '/api',
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: false,
}

export default appConfig
