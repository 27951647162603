import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllNotification: false,
    markAllNotification: false,
    markSingleNotification: false,
  },
  allNotification: [],
}
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    getAllNotificationStart: (state) => {
      state.isLoading.getAllNotification = true
    },

    getAllNotificationSuccess: (state, { payload }) => {
      state.isLoading.getAllNotification = false
      state.allNotification = payload
    },
    setAllNotificationSuccess: (state, { payload }) => {
      state.allNotification = payload
    },
    getAllNotificationFail: (state) => {
      state.isLoading.getAllNotification = false
    },
    markAllNotificationStart: (state) => {
      state.isLoading.markAllNotification = true
    },

    markAllNotificationSuccess: (state, { payload }) => {
      state.isLoading.markAllNotification = false
    },
    markAllNotificationFail: (state) => {
      state.isLoading.markAllNotification = false
    },
    markSingleNotificationStart: (state) => {
      state.isLoading.markSingleNotification = true
    },
    markSingleNotificationSuccess: (state, { payload }) => {
      state.isLoading.markSingleNotification = false
    },
    markSingleNotificationFail: (state) => {
      state.isLoading.markSingleNotification = false
    },
  },
})
export default notificationSlice.reducer
export const {
  getAllNotificationFail,
  getAllNotificationStart,
  getAllNotificationSuccess,
  setAllNotificationSuccess,
  markAllNotificationFail,
  markAllNotificationStart,
  markAllNotificationSuccess,
  markSingleNotificationFail,
  markSingleNotificationStart,
  markSingleNotificationSuccess,
} = notificationSlice.actions
