import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  deleteExperienceFail,
  deleteExperienceStart,
  deleteExperienceSuccess,
  getAllExperiencesFail,
  getAllExperiencesStart,
  getAllExperiencesSuccess,
  updateExperienceStatusFail,
  updateExperienceStatusStart,
  updateExperienceStatusSuccess,
  getExperienceStart,
  getExperienceSuccess,
  getExperienceFail,
  getAllTemplateStart,
  getAllTemplateSuccess,
  getAllTemplateFail,
  createTemplateStart,
  createTemplateSuccess,
  createTemplateFail,
  viewTemplateStart,
  viewTemplateSuccess,
  viewTemplateFail,
  deleteTemplateStart,
  deleteTemplateSuccess,
  deleteTemplateFail,
  getAllCategoryFail,
  getAllCategoryStart,
  getAllCategorySuccess,
  updateTemplateStatusStart,
  updateTemplateStatusSuccess,
  updateTemplateStatusFail,
} from 'store/reducer/experienceManagement'

// Get All Experiences
export function* getAllExperiencesSaga(action) {
  yield put(getAllExperiencesStart())
  const { onSuccess, startIndex, qString } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/experiences?startIndex=${startIndex}&${qString}`,
    successHandler: yield function* (response) {
      yield put(getAllExperiencesSuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(getAllExperiencesFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Update Experience Status
export function* updateExperienceStatusSaga(action) {
  yield put(updateExperienceStatusStart())
  const { onSuccess, onFail, ExperienceId, status } = action.payload
  const statusStr = status ? 'block' : 'unblock'
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/identity/Experiences/${ExperienceId}/${statusStr}`,
    successHandler: yield function* (response) {
      yield put(
        updateExperienceStatusSuccess({
          id: ExperienceId,
          status,
        })
      )
      onSuccess?.(response)
    },
    failHandler: yield function* (response) {
      yield put(updateExperienceStatusFail(response))
      onFail?.(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'patch',
  })
}

// Delete Experience
export function* deleteExperienceSaga(action) {
  yield put(deleteExperienceStart())
  const { onSuccess, onFail, ExperienceId } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/identity/Experiences/${ExperienceId}`,
    successHandler: yield function* (response) {
      yield put(deleteExperienceSuccess(response.data))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(deleteExperienceFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'delete',
  })
}

// Get Experience
export function* getExperienceSaga(action) {
  yield put(getExperienceStart())
  const { onSuccess, onFail, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/experiences/${id}`,
    successHandler: yield function* (response) {
      yield put(getExperienceSuccess(response.data.items[0]))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(getExperienceFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getAllTemplateSaga(action) {
  yield put(getAllTemplateStart())
  const { onSuccess, startIndex = 1, qString } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/experience-template?startIndex=${startIndex}&${qString || ''}`,
    successHandler: yield function* (response) {
      yield put(getAllTemplateSuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(getAllTemplateFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* createTemplateSaga(action) {
  yield put(createTemplateStart())
  const { onSuccess, data, isEdit, id } = action.payload
  const url = isEdit ? `/experience-template/${id}` : '/experience-template'
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: url,
    successHandler: yield function* (response) {
      yield put(createTemplateSuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(createTemplateFail(response))
      openNotification('danger', response)
    },
    payload: data,
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: isEdit ? 'patch' : 'post',
  })
}

export function* viewTemplateSaga(action) {
  yield put(viewTemplateStart())
  const { onSuccess, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/experience-template/${id}`,
    successHandler: yield function* (response) {
      yield put(viewTemplateSuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(viewTemplateFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* deleteTemplateSaga(action) {
  yield put(deleteTemplateStart())
  const { onSuccess, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/experience-template/${id}`,
    successHandler: yield function* (response) {
      yield put(deleteTemplateSuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(deleteTemplateFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'delete',
  })
}

export function* getAllCategorySaga(action) {
  yield put(getAllCategoryStart())
  const { onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/experience-template-category?q[sort]=name',
    successHandler: yield function* (response) {
      yield put(getAllCategorySuccess(response.data))
      onSuccess?.(response.data.items)
    },
    failHandler: yield function* (response) {
      yield put(getAllCategoryFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* updateTemplateStatusSaga(action) {
  yield put(updateTemplateStatusStart())
  const { onSuccess, id, onFail, status } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/experience-template/${id}/${status ? 'enable' : 'disable'}`,
    successHandler: yield function* (response) {
      yield put(updateTemplateStatusSuccess({ id }))
      onSuccess?.(response?.data?.message)
    },
    failHandler: yield function* (response) {
      yield put(updateTemplateStatusFail(response))
      onFail?.()
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'patch',
  })
}
