import { put } from 'redux-saga/effects'

import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  getAllRolesFail,
  getAllRolesStart,
  getAllRolesSuccess,
  getRoleFail,
  getRoleStart,
  getRoleSuccess,
  createRoleFail,
  createRoleStart,
  createRoleSuccess,
  deleteRoleStart,
  deleteRoleSuccess,
  deleteRoleFail,
  getAclEndpointFail,
  getAclEndpointStart,
  getAclEndpointSuccess,
  updateRoleFail,
  updateRoleStart,
  updateRoleSuccess,
} from 'store/reducer/role'

// Get All Roles
export function* getAllRolesSaga(action) {
  yield put(getAllRolesStart())
  const {
    onSuccess,
    startIndex = 1,
    itemsPerPage = 10,
    searchText,
    qString,
  } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/role?startIndex=${startIndex}&itemsPerPage=${itemsPerPage}&${
      qString || ''
    }`,
    successHandler: yield function* (response) {
      yield put(getAllRolesSuccess(response))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(getAllRolesFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Create Role
export function* createRoleSaga(action) {
  yield put(createRoleStart())
  const { onSuccess, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/role`,
    successHandler: yield function* (response) {
      yield put(createRoleSuccess(response.data))
      openNotification('success', response?.message)
      onSuccess?.()
      yield getAllRolesSaga({ payload: {} })
    },
    failHandler: yield function* (response) {
      yield put(createRoleFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'post',
  })
}

// Get Role
export function* getRoleSaga(action) {
  yield put(getRoleStart())
  const { onSuccess, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/roles/${id}`,
    successHandler: yield function* (response) {
      yield put(getRoleSuccess(response?.data?.items[0]))
      onSuccess?.(response?.data?.items[0])
    },
    failHandler: yield function* (response) {
      yield put(getRoleFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Delete Role
export function* deleteRoleSaga(action) {
  yield put(deleteRoleStart())
  const { onSuccess, onFail, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/role/delete/${id}`,
    successHandler: yield function* () {
      yield put(deleteRoleSuccess())
      openNotification('success', 'Role deleted successfully')
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(deleteRoleFail())
      openNotification('danger', response)
      onFail?.()
    },
    failHandlerType: 'CUSTOM',
    apiType: 'patch',
    token: true,
  })
}

// Get ACL Endpoints
export function* getAclEndpointsSaga(action) {
  yield put(getAclEndpointStart())
  const { onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/roles/acl-endpoints`,
    successHandler: yield function* (response) {
      yield put(getAclEndpointSuccess(response?.data?.items))
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(getAclEndpointFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Update Role
export function* updateRoleSaga(action) {
  yield put(updateRoleStart())
  const { onSuccess, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/role`,
    successHandler: yield function* (response) {
      yield put(updateRoleSuccess(response?.data?.items[0]))
      openNotification('success', response?.message)
      onSuccess?.(response?.data?.items[0])
      yield getAllRolesSaga({ payload: {} })
    },
    failHandler: yield function* (response) {
      yield put(updateRoleFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
