import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  UpdateContentFail,
  UpdateContentStart,
  UpdateContentSuccess,
  getAllContentFail,
  getAllContentStart,
  getAllContentSuccess,
} from 'store/reducer/contentManagementSystem'

export function* getAllContentSaga() {
  yield put(getAllContentStart())
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/cms`,
    successHandler: yield function* (response) {
      yield put(getAllContentSuccess(response.data))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getAllContentFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* updateCmsSaga(action) {
  yield put(UpdateContentStart())
  const { data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/cms`,
    successHandler: yield function* (response) {
      yield put(UpdateContentSuccess(response.data))
      //onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(UpdateContentFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
