import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  getAllHistoryFail,
  getAllHistoryStart,
  getAllHistorySuccess,
  getAllVariableFail,
  getAllVariableStart,
  getAllVariableSuccess,
  updateFail,
  updateStart,
  updateSuccess,
} from 'store/reducer/platformVariable'

export function* getAllVariableSaga(action) {
  yield put(getAllVariableStart())
  const { startIndex, startDate, endDate, search } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/platform-variable/get-platform-variables`,
    successHandler: yield function* (response) {
      yield put(getAllVariableSuccess(response.data))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getAllVariableFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
      startDate,
      endDate,
      search,
    },
  })
}
export function* getAllHistorySaga(action) {
  yield put(getAllHistoryStart())
  const { id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/platform-variable/get-platform-variable-history`,
    successHandler: yield function* (response) {
      yield put(getAllHistorySuccess(response.data))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getAllHistoryFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      _platformVariable: id,
    },
  })
}
export function* updateVariableSaga(action) {
  yield put(updateStart())
  const { data, onSuccess, onFail } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/platform-variable/update-platform-variable`,
    successHandler: yield function* (response) {
      yield put(updateSuccess(response.data))
      onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(updateFail(response))
      onFail(response)
      openNotification('danger', response?.message)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
