import React from 'react'
import { Skeleton } from 'components/ui'

const DetailBox = ({
  title,
  value,
  titleClass,
  valueClass,
  containerClass,
  loading,
}) => {
  return (
    <>
      <div className={`flex items-center py-2 ${containerClass}`}>
        <div
          className={`min-w-[160px] text-lg font-semibold text-slate-900 ${titleClass}`}
        >
          {title}
        </div>
        <div className={`text-base font-medium text-slate-900 ${valueClass}`}>
          {loading ? (
            <Skeleton animation="true" className="w-full min-w-[200px]" />
          ) : (
            value
          )}
        </div>
      </div>
    </>
  )
}

export default DetailBox
