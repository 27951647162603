import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllRoles: false,
    getRole: false,
    deleteRole: false,
    getAclEndpoint: false,
    createRole: false,
    updateRole: false,
  },
  allRoles: {},
  roleData: {},
  aclEndpoints: [],
}

export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // Get All Roles
    getAllRolesStart: (state) => {
      state.isLoading.getAllRoles = true
    },
    getAllRolesSuccess: (state, { payload }) => {
      state.isLoading.getAllRoles = false
      state.allRoles = payload
    },
    getAllRolesFail: (state) => {
      state.isLoading.getAllRoles = false
    },

    // Create Role
    createRoleStart: (state) => {
      state.isLoading.createRole = true
    },
    createRoleSuccess: (state) => {
      state.isLoading.createRole = false
    },
    createRoleFail: (state) => {
      state.isLoading.createRole = false
    },

    // Get Role
    getRoleStart: (state) => {
      state.isLoading.getRole = true
    },
    getRoleSuccess: (state, { payload }) => {
      state.isLoading.getRole = false
      state.roleData = payload
    },
    getRoleFail: (state) => {
      state.isLoading.getRole = false
    },

    // Delete Role
    deleteRoleStart: (state) => {
      state.isLoading.deleteRole = true
    },
    deleteRoleSuccess: (state) => {
      state.isLoading.deleteRole = false
    },
    deleteRoleFail: (state) => {
      state.isLoading.deleteRole = false
    },

    // Get ACL Endpoints
    getAclEndpointStart: (state) => {
      state.isLoading.getAclEndpoint = true
    },
    getAclEndpointSuccess: (state, { payload }) => {
      state.isLoading.getAclEndpoint = false
      state.aclEndpoints = payload
    },
    getAclEndpointFail: (state) => {
      state.isLoading.getAclEndpoint = false
    },

    // Update Role
    updateRoleStart: (state) => {
      state.isLoading.updateRole = true
    },
    updateRoleSuccess: (state, { payload }) => {
      state.isLoading.updateRole = false
      state.roleData = payload
    },
    updateRoleFail: (state) => {
      state.isLoading.updateRole = false
    },
  },
})

export default roleSlice.reducer
export const {
  getAllRolesStart,
  getAllRolesSuccess,
  getAllRolesFail,
  getRoleStart,
  getRoleSuccess,
  getRoleFail,
  createRoleStart,
  createRoleSuccess,
  createRoleFail,
  deleteRoleStart,
  deleteRoleSuccess,
  deleteRoleFail,
  getAclEndpointStart,
  getAclEndpointSuccess,
  getAclEndpointFail,
  updateRoleStart,
  updateRoleSuccess,
  updateRoleFail,
} = roleSlice.actions
