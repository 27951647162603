import { put } from 'redux-saga/effects'

import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  createAdminFail,
  createAdminStart,
  createAdminSuccess,
  deleteAdminFail,
  deleteAdminStart,
  deleteAdminSuccess,
  disableAdminFail,
  disableAdminStart,
  disableAdminSuccess,
  getActivityLogsFail,
  getActivityLogsStart,
  getActivityLogsSuccess,
  getAdminFail,
  getAdminStart,
  getAdminSuccess,
  getAllAdminsFail,
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllPendingInvitesFail,
  getAllPendingInvitesStart,
  getAllPendingInvitesSuccess,
  getPendingInviteFail,
  getPendingInviteStart,
  getPendingInviteSuccess,
  resendInviteStart,
  resendInviteSuccess,
  resendInviteFail,
  updateAdminStart,
  updateAdminSuccess,
  updateAdminFail,
} from 'store/reducer/admin'

// Create Admin
export function* createAdminSaga(action) {
  yield put(createAdminStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/admin/sub-admin',
    successHandler: yield function* (response) {
      yield put(createAdminSuccess())
      openNotification('success', 'Sub admin created successfully')
      onSuccess?.()
      yield getAllAdminsSaga({ payload: { startIndex: 1 } })
    },
    failHandler: yield function* (response) {
      yield put(createAdminFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    token: true,
    apiType: 'post',
  })
}

// Get All Admins
export function* getAllAdminsSaga(action) {
  yield put(getAllAdminsStart())
  const { onSuccess, startIndex, qString } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/sub-admin?startIndex=${startIndex}&itemsPerPage=10&${
      qString || ''
    }`,
    successHandler: yield function* (response) {
      yield put(getAllAdminsSuccess(response))
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(getAllAdminsFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Get Admin
export function* getAdminSaga(action) {
  yield put(getAdminStart())
  const { onSuccess, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/${id}`,
    successHandler: yield function* (response) {
      yield put(getAdminSuccess(response?.data?.items[0]))
      onSuccess?.(response?.data?.items[0])
    },
    failHandler: yield function* (response) {
      yield put(getAdminFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Update Admin
export function* updateAdminSaga(action) {
  yield put(updateAdminStart())
  const { onSuccess, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/sub-admin`,
    successHandler: yield function* (response) {
      yield put(updateAdminSuccess(response?.data?.items[0]))
      openNotification('success', 'Admin updated successfully')
      onSuccess?.()
      yield getAllAdminsSaga({ payload: { startIndex: 1 } })
    },
    failHandler: yield function* (response) {
      yield put(updateAdminFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// Disable Admin Status
export function* disableAdminSaga(action) {
  yield put(disableAdminStart())
  const { onSuccess, onFailed, adminId, status } = action.payload
  const statusStr = status ? 'enable' : 'disable'
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/${adminId}/status/${statusStr}`,
    successHandler: yield function* (response) {
      yield put(disableAdminSuccess())
      onSuccess?.(status)
    },
    failHandler: yield function* (response) {
      yield put(disableAdminFail(response))
      onFailed?.()
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'patch',
  })
}

// Delete Admin
export function* deleteAdminSaga(action) {
  yield put(deleteAdminStart())
  const { onSuccess, onFailed, userId, data, type } = action.payload
  let url = `/admin/sub-admin/remove-account/${userId}`
  if (type !== 'Delete') {
    url = '/admin/sub-admin/update-status'
  }
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: url,
    successHandler: yield function* (response) {
      yield put(deleteAdminSuccess())
      openNotification('success', response?.message)
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(deleteAdminFail(response))
      openNotification('danger', response)
      onFailed?.()
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'patch',
    ...(type !== 'Delete' ? { payload: data } : {}),
  })
}

// Get Activity Logs
export function* getActivityLogsSaga(action) {
  yield put(getActivityLogsStart())
  const { adminId, startIndex, onSuccess, onFailed } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/${adminId}/activity-logs?startIndex=${startIndex}&itemsPerPage=10`,
    successHandler: yield function* (response) {
      yield put(getActivityLogsSuccess(response?.data))
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(getActivityLogsFail(response))
      onFailed?.()
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Get All Pending Invites
export function* getAllPendingInvitesSaga(action) {
  yield put(getAllPendingInvitesStart())
  const { onSuccess, startIndex, qString } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/invites?startIndex=${startIndex}&${qString}`,
    successHandler: yield function* (response) {
      yield put(getAllPendingInvitesSuccess(response.data))
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(getAllPendingInvitesFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Get Pending Invite
export function* getPendingInviteSaga(action) {
  yield put(getPendingInviteStart())
  const { onSuccess, id } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/invites/${id}`,
    successHandler: yield function* (response) {
      yield put(getPendingInviteSuccess(response?.data?.items[0]))
      onSuccess?.(response?.data?.items[0])
    },
    failHandler: yield function* (response) {
      yield put(getPendingInviteFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Resend Invite
export function* resendInviteSaga(action) {
  yield put(resendInviteStart())
  const { id, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/invites/${id}/resend-invite`,
    successHandler: yield function* (response) {
      yield put(resendInviteSuccess())
      openNotification('success', 'Invite Link Sent Successfully')
      onSuccess?.(response?.data?.items[0])
    },
    failHandler: yield function* (response) {
      yield put(resendInviteFail())
      openNotification('danger', response)
    },
    token: true,
    apiType: 'post',
    failHandlerType: 'CUSTOM',
  })
}
