import { createAction } from '@reduxjs/toolkit'

export const getAllUsersSagaAction = createAction('GET_ALL_USERS_SAGA')
export const getAllInvestorUsersSagaAction = createAction(
  'GET_ALL_INVESTOR_USER_SAGA'
)
export const updateUserStatusSagaAction = createAction(
  'UPDATE_USER_STATUS_SAGA'
)
export const deleteUserSagaAction = createAction('DELETE_USER_SAGA')
export const getMomentUserSagaAction = createAction('GET_MOMENT_USER_SAGA')
export const getIdentityeUserSagaAction = createAction('GET_IDENTITY_USER_SAGA')
export const getAllUserSpecificTeamsSagaAction = createAction(
  'GET_ALL_USER_SPECIFIC_TEAMS_SAGA'
)
export const getAllUserSpecificExperiencesSagaAction = createAction(
  'GET_ALL_USER_SPECIFIC_EXPERIENCES_SAGA'
)
export const reviewExperienceSagaAction = createAction('REVIEW_EXPERIENCE_SAGA')
export const getExperienceSagaAction = createAction('GET_EXPERIENCE_SAGA')
export const getKybQuestionsSagaAction = createAction('GET_KYB_QUESTIONS_SAGA')
export const getVintageYearsSagaAction = createAction('GET_VINTAGE_YEARS_SAGA')
export const getMonitoringVccSagaAction = createAction(
  'GET_MONITORING_VCC_SAGA'
)

export const getProjectListingSagaAction = createAction(
  'GET_PROJECT_LISTING_SAGA'
)
export const getInvestedProjectListSagaAction = createAction(
  'GET_INVESTED_PROJECT_LIST_SAGA'
)
export const getProjectInvestedSagaAction = createAction(
  'GET_PROJECT_INVESTED_SAGA'
)

export const requestToReuploadDocSagaAction = createAction(
  'REQUEST_TO_REUPLOAD_DOC_SAGA'
)

export const approveSingleKybDocSagaAction = createAction(
  'APPROVE_SINGLE_KYB_DOC_SAGA'
)

export const submitKybSagaAction = createAction('SUBMIT_KYB_SAGA')

export const rejectKybSagaAction = createAction('REJECT_KYB_SAGA')

export const approveKybSagaAction = createAction('APPROVE_KYB_SAGA')
export const approveKybQuestionSagaAction = createAction(
  'APPROVE_KYB_QUESTION_SAGA'
)

export const requestToUpdateQuestionAnswerSagaAction = createAction(
  'REQUEST_TO_UPDATE_QUESTION_ANSWER_SAGA'
)
