import React from 'react'
import { HiOutlineChevronRight } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ data }) => {
  return (
    <h3 className="mb-4 lg:mb-0 text-xl font-semibold flex items-center gap-3">
      {data.map((item, index) => {
        return (
          <>
            {index > 0 && (
              <span>
                <HiOutlineChevronRight />{' '}
              </span>
            )}
            <Link to={item.link}>{item.name}</Link>
          </>
        )
      })}
    </h3>
  )
}

export default Breadcrumb
