import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: {
        getAllVariable: false,
        getAllHistory: false,
        updateVariable: false
    },
    allVariable:[],
    allHistory:[]
}
export const platFormVariableSlice = createSlice({
    name: 'platformVariable',
    initialState,
    reducers: {
          getAllVariableStart: (state) => {
            state.isLoading.getAllVariable  = true
          },
      
          getAllVariableSuccess: (state, { payload }) => {
            state.isLoading.getAllVariable = false
            state.allVariable = payload
          },
          getAllVariableFail: (state) => {
            state.isLoading.getAllVariable = false
          },
          getAllHistoryStart: (state) => {
            state.isLoading.getAllHistory  = true
          },
      
          getAllHistorySuccess: (state, { payload }) => {
            state.isLoading.getAllHistory = false
            state.allHistory = payload
          },
          getAllHistoryFail: (state) => {
            state.isLoading.getAllHistory = false
          },
          updateStart: (state) => {
            state.isLoading.updateVariable = true
          },
          updateSuccess: (state, { payload }) => {
            state.isLoading.updateVariable = false
          },
          updateFail: (state) => {
            state.isLoading.updateVariable = false
          },
    
    }
})
export default platFormVariableSlice.reducer
export const { 
    getAllVariableStart,
    getAllVariableSuccess,
    getAllVariableFail,
    getAllHistoryFail,
    getAllHistoryStart,
    getAllHistorySuccess,
    updateFail,
    updateStart,
    updateSuccess
 } = platFormVariableSlice.actions