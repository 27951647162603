import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllTeams: false,
    getTeam: false,
    getTeamMembers: false,
  },
  allTeams: {},
  team: {},
  allTeamMembers: {},
}

export const teamManagementSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // Get All Teams
    getAllTeamsStart: (state) => {
      state.isLoading.getAllTeams = true
    },
    getAllTeamsSuccess: (state, { payload }) => {
      state.isLoading.getAllTeams = false
      state.allTeams = payload
    },
    getAllTeamsFail: (state) => {
      state.isLoading.getAllTeams = false
    },

    getTeamStart: (state) => {
      state.isLoading.getTeam = true
    },
    getTeamSuccess: (state, { payload }) => {
      state.isLoading.getTeam = false
      state.team = payload
    },
    getTeamFail: (state) => {
      state.isLoading.getTeam = false
    },

    getTeamMembersStart: (state) => {
      state.isLoading.getTeamMembers = true
    },
    getTeamMembersSuccess: (state, { payload }) => {
      state.isLoading.getTeamMembers = false
      state.allTeamMembers = payload
    },
    getTeamMembersFail: (state) => {
      state.isLoading.getTeamMembers = false
    },
  },
})

export default teamManagementSlice.reducer
export const {
  getAllTeamsStart,
  getAllTeamsSuccess,
  getAllTeamsFail,
  getTeamStart,
  getTeamSuccess,
  getTeamFail,
  getTeamMembersStart,
  getTeamMembersSuccess,
  getTeamMembersFail,
} = teamManagementSlice.actions
