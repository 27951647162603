import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllDraft: false,
    getAllPublished: false,
    getProjectDetails: false,
    getProjectReview: false,
    getMessageHistory: false,
    getReplyWithMessage: false,
    getSubmitReview: false,
    getProjectFccHistory: false,
    getProjectVccHistory: false,
    getProjectVccListing: false,
    fccMintingRequestApprove: false,
    fccMintingRequestReject: false,
    verificationCreditIssuancew: false,
    approveDesign: false,
    approveDescription: false,
    approvePublicConsulation: false,
    approveDescriptionfinalization: false,
    approveMonitoring: false,
    requestChanges: false,
    closePublicConsulation: false,
    getVintageYears: false,
    getMonitoringVcc: false,
    updatingActive: false,
  },
  allDraft: {},
  allDraftPage: {},
  allPublished: {},
  allPublishedPage: {},
  projectDetails: {},
  kybQuestionList: [],
  vintageYearsList: [],
  monitoringVccList: [],
  fccHistoryData: {},
  vccHistoryData: {},
  vccListingData: {},
  projectReview: '',
  messageHistory: '',
  projectFccHistory: {},
  replyWithMessage: '',
}
export const projectManagementSlice = createSlice({
  name: 'projectManagement',
  initialState,
  reducers: {
    // Get All Draft
    getAllDraftStart: (state) => {
      state.isLoading.getAllDraft = true
    },

    getAllDraftSuccess: (state, { payload }) => {
      state.isLoading.getAllDraft = false
      state.allDraft = payload.data
      state.allDraftPage = payload
    },
    getAllDraftFail: (state) => {
      state.isLoading.getAllDraft = false
    },

    // Get All Published

    getAllPublishedStart: (state) => {
      state.isLoading.getAllPublished = true
    },

    getAllPublishedSuccess: (state, { payload }) => {
      state.isLoading.getAllPublished = false
      state.allPublished = payload.data
      state.allPublishedPage = payload
    },
    getAllPublishedFail: (state) => {
      state.isLoading.getAllPublished = false
    },

    // ProjectDetails
    getProjectDetailStart: (state) => {
      state.isLoading.getProjectDetails = true
    },

    getProjectDetailSuccess: (state, { payload }) => {
      // console.log(payload, 'projectDeveloperTable payload')
      state.isLoading.getProjectDetails = false
      state.projectDetails = payload
    },
    getProjectDetailFail: (state) => {
      state.isLoading.getProjectDetails = false
    },

    // project review validation
    getProjectReviewStart: (state) => {
      state.isLoading.getProjectReview = true
    },

    getProjectReviewSuccess: (state, { payload }) => {
      state.isLoading.getProjectReview = false
      state.projectReview = payload.message
    },
    getProjectReviewFail: (state) => {
      state.isLoading.getProjectReview = false
    },

    // message history
    getMessageHistoryStart: (state) => {
      state.isLoading.getMessageHistory = true
    },

    getMessageHistorySuccess: (state, { payload }) => {
      state.isLoading.getMessageHistory = false
      state.messageHistory = payload
    },
    getMessageHistoryFail: (state) => {
      state.isLoading.getMessageHistory = false
    },
    //replywithmessage
    getReplyWithMessageStart: (state) => {
      state.isLoading.getReplyWithMessage = true
    },

    getReplyWithMessageSuccess: (state, { payload }) => {
      state.isLoading.getReplyWithMessage = false
    },
    getReplyWithMessageFail: (state) => {
      state.isLoading.getReplyWithMessage = false
    },

    //submit review
    submitReviewStart: (state) => {
      state.isLoading.getSubmitReview = true
    },
    submitReviewSuccess: (state, { payload }) => {
      state.isLoading.getSubmitReview = false
    },
    submitReviewFail: (state) => {
      state.isLoading.getSubmitReview = false
    },
    // approve fcc request
    approvefccStart: (state) => {
      state.isLoading.fccMintingRequestApprove = true
    },
    approvefccSuccess: (state, { payload }) => {
      state.isLoading.fccMintingRequestApprove = false
    },
    approvefccFail: (state) => {
      state.isLoading.fccMintingRequestApprove = false
    },
    // reject fcc request
    rejectfccStart: (state) => {
      state.isLoading.fccMintingRequestReject = true
    },
    rejectfccSucess: (state, { payload }) => {
      state.isLoading.fccMintingRequestReject = false
    },
    rejectfccFail: (state) => {
      state.isLoading.fccMintingRequestReject = false
    },
    // Verification & Credit Issuance
    verificationCreditIssuanceStart: (state) => {
      state.isLoading.verificationCreditIssuancew = true
    },
    verificationCreditIssuanceSuccess: (state, { payload }) => {
      state.isLoading.verificationCreditIssuancew = false
    },
    verificationCreditIssuanceFail: (state) => {
      state.isLoading.verificationCreditIssuancew = false
    },
    // approve design
    approveDesignStart: (state) => {
      state.isLoading.approveDesign = true
    },
    approveDesignSuccess: (state, { payload }) => {
      state.isLoading.approveDesign = false
    },
    approveDesignFail: (state) => {
      state.isLoading.approveDesign = false
    },
    // approve description
    approveDescriptionStart: (state) => {
      state.isLoading.approveDescription = true
    },
    approveDescriptionSuccess: (state, { payload }) => {
      state.isLoading.approveDescription = false
    },
    approveDescriptionnFail: (state) => {
      state.isLoading.approveDescription = false
    },
    // approve public consulation
    approvePublicConsulationStart: (state) => {
      state.isLoading.approvePublicConsulation = true
    },
    approvePublicConsulationSuccess: (state, { payload }) => {
      state.isLoading.approvePublicConsulation = false
    },
    approvePublicConsulationFail: (state) => {
      state.isLoading.approvePublicConsulation = false
    },

    closePublicConsulationStart: (state) => {
      state.isLoading.closePublicConsulation = true
    },
    closePublicConsulationSuccess: (state, { payload }) => {
      state.isLoading.closePublicConsulation = false
    },
    closePublicConsulationFail: (state) => {
      state.isLoading.closePublicConsulation = false
    },
    // approve description final
    approveDescriptionFinalStart: (state) => {
      state.isLoading.approveDescriptionfinalization = true
    },
    approveDescriptionFinalSuccess: (state, { payload }) => {
      state.isLoading.approveDescriptionfinalization = false
    },
    approveDescriptionFinalFail: (state) => {
      state.isLoading.approveDescriptionfinalization = false
    },

    //update Project id

    updateProjectIdStart: (state) => {
      state.isLoading.updatingID = true
    },
    updateProjectIdSuccess: (state, { payload }) => {
      state.isLoading.updatingID = false
    },
    updateProjectIdFail: (state) => {
      state.isLoading.updatingID = false
    },

    // updateRegistryLink

    updateRegistryLinkStart: (state) => {
      state.isLoading.updatingLink = true
    },
    updateRegistryLinkSuccess: (state, { payload }) => {
      state.isLoading.updatingLink = false
    },
    updateRegistryLinkFail: (state) => {
      state.isLoading.updatingLink = false
    },

    //update change active

    updateChangeActiveStart: (state) => {
      state.isLoading.updatingActive = true
    },
    updateChangeActiveSuccess: (state, { payload }) => {
      state.isLoading.updatingActive = false
    },
    updateChangeActiveFail: (state) => {
      state.isLoading.updatingActive = false
    },

    approveMonitoringStart: (state) => {
      state.isLoading.approveMonitoring = true
    },
    approveMonitoringSuccess: (state, { payload }) => {
      state.isLoading.approveMonitoring = false
    },
    approveMonitoringlFail: (state) => {
      state.isLoading.approveMonitoring = false
    },
    // need changes
    requestChangesStart: (state) => {
      state.isLoading.requestChanges = true
    },
    requestChangesSuccess: (state, { payload }) => {
      state.isLoading.requestChanges = false
    },
    requestChangesFail: (state) => {
      state.isLoading.requestChanges = false
    },
    //get converstions
    getProjectUpdatesDetaisStart: (state) => {
      state.isLoading.isMessages = true
    },
    getProjectUpdatesDetailsSuccess: (state, { payload }) => {
      state.isLoading.isMessages = false
      state.messageData = payload
    },
    getProjectUpdatesDetailsFail: (state) => {
      state.isLoading.isMessages = false
    },
    //fcc history
    getProjectFccHistoryStart: (state) => {
      state.isLoading.getProjectFccHistory = true
    },
    getProjectFccHistorySuccess: (state, { payload }) => {
      state.isLoading.getProjectFccHistory = false
      state.fccHistoryData = payload
    },
    getProjectFccHistoryFail: (state) => {
      state.isLoading.getProjectFccHistory = false
    },
    //Vcc history
    getProjectVccHistoryStart: (state) => {
      state.isLoading.getProjectVccHistory = true
    },
    getProjectVccHistorySuccess: (state, { payload }) => {
      state.isLoading.getProjectVccHistory = false
      state.vccHistoryData = payload
    },
    getProjectVccHistoryFail: (state) => {
      state.isLoading.getProjectVccHistory = false
    },
    //Vcc Listing
    getProjectVccListingStart: (state) => {
      state.isLoading.getProjectVccListing = true
    },
    getProjectVccListingSuccess: (state, { payload }) => {
      state.isLoading.getProjectVccListing = false
      state.vccListingData = payload
    },
    getProjectVccListingFail: (state) => {
      state.isLoading.getProjectVccListing = false
    },
    //kyb questions
    getKybQuestionsStart: (state) => {
      state.isLoading.getKybQuestions = true
    },
    getKybQuestionsSuccess: (state, { payload }) => {
      state.isLoading.getKybQuestions = false
      state.kybQuestionList = payload
    },
    getKybQuestionsFail: (state) => {
      state.isLoading.getKybQuestions = false
    },
    //vintage years
    getVintageYearsStart: (state) => {
      state.isLoading.getVintageYears = true
    },
    getVintageYearsSuccess: (state, { payload }) => {
      state.isLoading.getVintageYears = false
      state.vintageYearsList = payload
    },
    getVintageYearsFail: (state) => {
      state.isLoading.getVintageYears = false
    },
    //monitoring vcc
    getMonitoringVccStart: (state) => {
      state.isLoading.getMonitoringVcc = true
    },
    getMonitoringVccSuccess: (state, { payload }) => {
      state.isLoading.getMonitoringVcc = false
      state.monitoringVccList = payload
    },
    getMonitoringVccFail: (state) => {
      state.isLoading.getMonitoringVcc = false
      state.monitoringVccList = []
    },
  },
})
export default projectManagementSlice.reducer
export const {
  getAllDraftFail,
  getAllDraftStart,
  getAllDraftSuccess,
  getAllPublishedFail,
  getAllPublishedStart,
  getAllPublishedSuccess,
  getProjectDetailFail,
  getProjectDetailStart,
  getProjectDetailSuccess,
  getProjectReviewFail,
  getProjectReviewStart,
  getProjectReviewSuccess,
  getMessageHistoryFail,
  getMessageHistoryStart,
  getMessageHistorySuccess,
  getReplyWithMessageFail,
  getReplyWithMessageStart,
  getReplyWithMessageSuccess,
  submitReviewFail,
  submitReviewStart,
  submitReviewSuccess,
  approvefccFail,
  approvefccStart,
  approvefccSuccess,
  rejectfccStart,
  rejectfccFail,
  rejectfccSucess,
  verificationCreditIssuanceStart,
  verificationCreditIssuanceSuccess,
  verificationCreditIssuanceFail,
  approveDesignFail,
  approveDesignStart,
  approveDesignSuccess,
  approveDescriptionFinalFail,
  approveDescriptionFinalStart,
  approveDescriptionFinalSuccess,
  approveDescriptionStart,
  approveDescriptionSuccess,
  approveDescriptionnFail,
  approvePublicConsulationFail,
  approvePublicConsulationStart,
  approvePublicConsulationSuccess,
  requestChangesFail,
  requestChangesStart,
  requestChangesSuccess,
  approveMonitoringStart,
  approveMonitoringSuccess,
  approveMonitoringlFail,
  getProjectUpdatesDetaisStart,
  getProjectUpdatesDetailsSuccess,
  getProjectUpdatesDetailsFail,
  closePublicConsulationFail,
  closePublicConsulationSuccess,
  closePublicConsulationStart,
  updateProjectIdStart,
  updateProjectIdSuccess,
  updateProjectIdFail,
  getProjectFccHistoryStart,
  getProjectFccHistorySuccess,
  getProjectFccHistoryFail,
  getProjectVccHistoryStart,
  getProjectVccHistorySuccess,
  getProjectVccHistoryFail,
  getProjectVccListingStart,
  getProjectVccListingSuccess,
  getProjectVccListingFail,
  getKybQuestionsStart,
  getKybQuestionsSuccess,
  getKybQuestionsFail,
  updateRegistryLinkStart,
  updateRegistryLinkSuccess,
  updateRegistryLinkFail,
  getVintageYearsSuccess,
  getVintageYearsFail,
  getVintageYearsStart,
  getMonitoringVccStart,
  getMonitoringVccSuccess,
  getMonitoringVccFail,
  updateChangeActiveFail,
  updateChangeActiveStart,
  updateChangeActiveSuccess,
} = projectManagementSlice.actions
