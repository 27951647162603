import React from 'react'
import { Spinner } from 'components/ui'
import AdaptableCard from './AdaptableCard'

const PageWrapper = ({ children, isLoading }) => {
  return (
    <AdaptableCard className="h-full" bodyClass="h-full">
      <div className="p-4 w-full">
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center z-50 bg-slate-900 bg-opacity-25">
            <Spinner size="2.5rem" />
          </div>
        )}
        {children}
      </div>
    </AdaptableCard>
  )
}

export default PageWrapper
