import { put } from 'redux-saga/effects'
import { BASE_URL } from 'constants/envs.constant'

import {
  authOTPVerificationFail,
  authOTPVerificationStart,
  authOTPVerificationSuccess,
  codeVerificationRequestFail,
  codeVerificationRequestStart,
  codeVerificationRequestSuccess,
  codeVerificationVerifyFail,
  codeVerificationVerifyStart,
  codeVerificationVerifySuccess,
  getTokenDetailFail,
  getTokenDetailStart,
  getTokenDetailSuccess,
  loginFail,
  loginStart,
  loginSuccess,
  logoutStart,
  logoutSuccess,
  registerFail,
  registerStart,
  registerSuccess,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  updateSettingFail,
  updateSettingStart,
  updateSettingSuccess,
} from 'store/reducer/auth/authSlice'
import errorHandler from 'utils/errorHandler'
import { openNotification } from 'utils/showToast'
import { showModal } from 'store/reducer/modal/modalSlice'

export function* loginSaga(action) {
  yield put(loginStart())
  const { data, onSuccess } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/admin/auth/login',
    successHandler: yield function* (response) {
      if (response?.data?.isFirstTimeLogin) {
        openNotification('success', response.message)
        window.location =
          '/reset-password?token=' +
          response?.data?.token +
          '&email=' +
          response?.data?.email
      } else {
        yield put(loginSuccess(response?.data))

        if (response?.data?.token) {
          localStorage.setItem('authToken', response?.data?.token)
          onSuccess({
            // loggedIn: true,
            data: {},
          })
        } else {
          // 2FA flow
          onSuccess({
            loggedIn: false,
            data: {},
            // data: response?.data?.items[0],
          })
        }
      }
    },
    failHandler: yield function* (response) {
      yield put(loginFail({}))
      if (response) {
        openNotification('danger', response)
      }
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'post',
  })
}

export function* registerSaga(action) {
  yield put(registerStart())
  const { data, onSuccess } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/auth/signup',
    successHandler: yield function* (response) {
      yield put(registerSuccess(response?.data))
      localStorage.setItem('authToken', response?.data?.token)
      yield put(showModal({ modalName: '2FA_MODAL' }))
      onSuccess()
    },
    failHandler: yield function* (response) {
      yield put(registerFail(response.error))
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'post',
  })
}

export function* tokenDetailSaga(action) {
  yield put(getTokenDetailStart())
  const { token, onSuccess, onFailed } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/code-verification/by-token/${token}`,
    successHandler: yield function* (response) {
      yield put(
        getTokenDetailSuccess(response?.data?.items[0]?.codeVerification)
      )
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(getTokenDetailFail(response))
      onFailed?.()
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    apiType: 'get',
  })
}

export function* codeVerificationRequestSaga(action) {
  yield put(codeVerificationRequestStart())
  const { data, token = false, onSuccess, onFailed } = action.payload

  let url = '/admin/auth/resend-otp'
  if (data.isForgot) {
    url = '/admin/auth/forgot-password'
    delete data.isForgot
  }
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: url,
    successHandler: yield function* (response) {
      yield put(
        codeVerificationRequestSuccess(
          response?.data?.items[0]?.codeVerification
        )
      )
      onSuccess?.(response?.data?.items?.[0]?.codeVerification)
    },
    failHandler: yield function* (response) {
      // console.log('response', response)
      yield put(codeVerificationRequestFail(response))
      onFailed?.(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    token,
    apiType: 'post',
  })
}

export function* codeVerificationVerifySaga(action) {
  yield put(codeVerificationVerifyStart())
  const { data, onSuccess, onFailed } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/code-verification/verify`,
    successHandler: yield function* (response) {
      yield put(
        codeVerificationVerifySuccess(
          response?.data?.items[0]?.codeVerification
        )
      )
      onSuccess?.(response?.data)
    },
    failHandler: yield function* (response) {
      yield put(codeVerificationVerifyFail(response))
      openNotification('danger', response)
      onFailed?.()
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'post',
  })
}

export function* resetPasswordSaga(action) {
  yield put(resetPasswordStart())
  const { data, onSuccess, onFailed, token, email } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/auth/reset-password?token=${token}`,
    successHandler: yield function* (response) {
      yield put(resetPasswordSuccess(response?.data))
      // localStorage.setItem('authToken', response?.data?.token)
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(resetPasswordFail(response.error))
      onFailed?.()
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'patch',
  })
}

export function* logoutSaga(action) {
  yield put(logoutStart())
  const { onSuccess, error, redirect } = action.payload
  if (error) {
    openNotification('danger', error)
  }
  yield localStorage.clear()
  yield put(logoutSuccess())
  if (redirect) {
    window.location = '/sign-in'
  }
  onSuccess?.()
}

export function* updateSettingUpdateSaga(action) {
  yield put(updateSettingStart())
  const { data, onSuccess, onFailed } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/me/settings',
    successHandler: yield function* (response) {
      yield put(updateSettingSuccess(response?.data))
      onSuccess?.(response?.data)
    },
    failHandler: yield function* (response) {
      yield put(updateSettingFail(response?.error))
      onFailed?.(response)
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    token: true,
    apiType: 'patch',
  })
}

export function* authOTPVerificationSaga(action) {
  yield put(authOTPVerificationStart())
  const { data, onSuccess } = action.payload

  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/admin/auth/verify-otp',
    successHandler: yield function* (response) {
      yield put(authOTPVerificationSuccess(response?.data))
      localStorage.setItem('authToken', response?.data?.authToken)
      onSuccess()
    },
    failHandler: yield function* (response) {
      yield put(authOTPVerificationFail(response?.error))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'post',
  })
}
