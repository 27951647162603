import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllTxs: false,
  },
  allTxs: {},
  allTxsData: {},
}

export const txManagementSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Get All Users
    getAllTxsStart: (state) => {
      state.isLoading.getAllTxs = true
    },

    getAllTxsSuccess: (state, { payload }) => {
      state.isLoading.getAllTxs = false
      state.allTxs = payload.data
      state.allTxsData = payload
    },
    getAllTxsFail: (state) => {
      state.isLoading.getAllTxs = false
    },
  },
})

export default txManagementSlice.reducer
export const { getAllTxsStart, getAllTxsSuccess, getAllTxsFail } =
  txManagementSlice.actions
