import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'auth/session',
  initialState: {
    token: '',
    signedIn: false,
    isLoading: {
      signIn: false,
    },
  },
  reducers: {
    onSignInStart: (state) => {
      state.isLoading.signIn = true
    },
    onSignInFailed: (state) => {
      state.isLoading.signIn = false
    },
    onSignInSuccess: (state, action) => {
      state.signedIn = true
      state.token = action.payload
    },
    onSignOutSuccess: (state) => {
      state.signedIn = false
      state.token = ''
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
  },
})

export const {
  onSignInSuccess,
  onSignOutSuccess,
  setToken,
  onSignInStart,
  onSignInFailed,
} = sessionSlice.actions

export default sessionSlice.reducer
