import { createAction } from '@reduxjs/toolkit'

export const getAllNotificationSagaAction = createAction(
  'GET_ALL_NOTIFICATION_SAGA'
)
export const markAllNotificationSagaAction = createAction('MARK_ALL_NOTIFICATION_SAGA')
export const markSingleNotificationSagaAction = createAction('MARK_SINGLE_NOTIFCATION_SAGA')

export const setAllNotificationSagaAction = createAction(
  'SET_ALL_NOTIFICATION_SAGA'
)