import { createAction } from '@reduxjs/toolkit'

export const getAdminSagaAction = createAction('GET_ADMIN_SAGA')
export const updateAdminSagaAction = createAction('UPDATE_ADMIN_SAGA')
export const createAdminSagaAction = createAction('CREATE_ADMIN_SAGA')
export const deleteAdminSagaAction = createAction('DELETE_ADMIN_SAGA')
export const getAllAdminsSagaAction = createAction('GET_ALL_ADMINS_SAGA')
export const disabledAdminSagaAction = createAction('DISABLED_ADMIN_SAGA')

export const activityLogsSagaAction = createAction('ACTIVITY_LOGS_SAGA')

export const resendInviteSagaAction = createAction('RESEND_INVITE_SAGA')
export const getPendingInviteSagaAction = createAction('GET_PENDING_SAGA')
export const getAllPendingInviteSagaAction = createAction(
  'GET_ALL_PENDING_INVITES'
)
