import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import { authReducer } from './authSlice'

const reducer = combineReducers({
  session,
  user,
  login: authReducer,
})

export default reducer
