import { combineReducers } from 'redux'

import theme from '../theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import modal from './modal'
import admin from './admin'
import role from './role'
import user from './userManagement'
import txManagement from './txManagement'
import experience from './experienceManagement'
import team from './teamManagement'
import projectManagement from './projectManagement'
import contentManagement from './contentManagementSystem'
import platformVariable from './platformVariable'
import notification from './notification'
import dashboard from './dashboard'
import vintageYear from './vintageYear'

const rootReducer = combineReducers({
  theme,
  auth,
  base,
  locale,
  modal,
  admin,
  role,
  user,
  txManagement,
  experience,
  team,
  projectManagement,
  contentManagement,
  platformVariable,
  notification,
  dashboard,
  vintageYear,
})

export default rootReducer
