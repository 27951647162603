import { createAction } from '@reduxjs/toolkit'

export const loginSagaAction = createAction('LOGIN_SAGA')
export const registerSagaAction = createAction('REGISTER_SAGA')
export const getTokenDetailSagaAction = createAction('GET_TOKEN_DETAIL_SAGA')
export const codeVeriRequestSagaAction = createAction(
  'CODE_VERIFICATION_REQUEST_SAGA'
)
export const codeVeriVerifySagaAction = createAction(
  'CODE_VERIFICATION_VERIFY_SAGA'
)
export const resetPasswordSagaAction = createAction('RESET_PASSWORD_SAGA')
export const logoutSagaAction = createAction('LOGOUT_SAGA')
export const updateSettingUpdateSagaAction = createAction(
  'UPDATE_SETTING_UPDATE_SAGA'
)
export const authOTPVerificationSagaAction = createAction('AUTH_OTP_VERIFICATION_SAGA')
