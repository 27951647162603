import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoading: {
    createAdmin: false,
    getAllAdmins: false,
    getAdmin: false,
    disableAdmin: false,
    deleteAdmin: false,
    getActivityLogs: false,
    getAllPendingInvites: false,
    getPendingInvite: false,
    resendInvite: false,
    updateAdmin: false,
  },
  allAdmins: {},
  allPendingInvites: {},
  pendingInvite: {},
  adminData: {},
  activityLogs: [],
  switchAdminStatusId: '',
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // Get All Admins
    getAllAdminsStart: (state) => {
      state.isLoading.getAllAdmins = true
    },
    getAllAdminsSuccess: (state, { payload }) => {
      state.isLoading.getAllAdmins = false
      state.allAdmins = payload
    },
    getAllAdminsFail: (state) => {
      state.isLoading.getAllAdmins = false
    },

    // Create Admin
    createAdminStart: (state) => {
      state.isLoading.createAdmin = true
    },
    createAdminSuccess: (state) => {
      state.isLoading.createAdmin = false
    },
    createAdminFail: (state) => {
      state.isLoading.createAdmin = false
    },

    //Get Admin
    getAdminStart: (state) => {
      state.isLoading.getAdmin = true
    },
    getAdminSuccess: (state, { payload }) => {
      state.isLoading.getAdmin = false
      state.adminData = payload
    },
    getAdminFail: (state) => {
      state.isLoading.getAdmin = false
      state.adminData = {}
    },

    //Update Admin
    updateAdminStart: (state) => {
      state.isLoading.updateAdmin = true
    },
    updateAdminSuccess: (state, { payload }) => {
      state.isLoading.updateAdmin = false
      state.adminData = payload
    },
    updateAdminFail: (state) => {
      state.isLoading.updateAdmin = false
    },

    // Switch Admin Status ID
    setSwitchAdminId: (state, { payload }) => {
      state.switchAdminStatusId = payload
    },

    // Disable Admin
    disableAdminStart: (state) => {
      state.isLoading.disableAdmin = true
    },
    disableAdminSuccess: (state) => {
      state.isLoading.disableAdmin = false
    },
    disableAdminFail: (state) => {
      state.isLoading.disableAdmin = false
    },

    //Delete Admin
    deleteAdminStart: (state) => {
      state.isLoading.deleteAdmin = true
    },
    deleteAdminSuccess: (state) => {
      state.isLoading.deleteAdmin = false
    },
    deleteAdminFail: (state) => {
      state.isLoading.deleteAdmin = false
    },

    // Get Activity Logs
    getActivityLogsStart: (state) => {
      state.isLoading.getActivityLogs = true
    },
    getActivityLogsSuccess: (state, { payload }) => {
      state.isLoading.getActivityLogs = false
      state.activityLogs = payload
    },
    getActivityLogsFail: (state) => {
      state.isLoading.getActivityLogs = false
    },

    // Get All Pending Invites
    getAllPendingInvitesStart: (state) => {
      state.isLoading.getAllPendingInvites = true
    },
    getAllPendingInvitesSuccess: (state, { payload }) => {
      state.isLoading.getAllPendingInvites = false
      state.allPendingInvites = payload
    },
    getAllPendingInvitesFail: (state) => {
      state.isLoading.getAllPendingInvites = false
    },

    //Get Pending Invite
    getPendingInviteStart: (state) => {
      state.isLoading.getPendingInvite = true
    },
    getPendingInviteSuccess: (state, { payload }) => {
      state.isLoading.getPendingInvite = false
      state.pendingInvite = payload
    },
    getPendingInviteFail: (state) => {
      state.isLoading.getPendingInvite = false
    },

    // Resend Invite
    resendInviteStart: (state) => {
      state.isLoading.resendInvite = true
    },
    resendInviteSuccess: (state) => {
      state.isLoading.resendInvite = false
    },
    resendInviteFail: (state) => {
      state.isLoading.resendInvite = false
    },
    // Update role ID
    updateRoleId: (state, { payload }) => {
      const roleIndex = state.allPendingInvites.items.findIndex(
        (role) => role._id === payload.oldId
      )
      if (roleIndex !== -1) {
        state.allPendingInvites.items[roleIndex]._id = payload.newId
      }
    },
  },
})

export default adminSlice.reducer
export const {
  createAdminStart,
  createAdminSuccess,
  createAdminFail,
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFail,
  getAdminStart,
  getAdminSuccess,
  getAdminFail,
  setSwitchAdminId,
  disableAdminStart,
  disableAdminSuccess,
  disableAdminFail,
  deleteAdminStart,
  deleteAdminSuccess,
  deleteAdminFail,
  getActivityLogsStart,
  getActivityLogsSuccess,
  getActivityLogsFail,
  getAllPendingInvitesStart,
  getAllPendingInvitesSuccess,
  getAllPendingInvitesFail,
  getPendingInviteStart,
  getPendingInviteSuccess,
  getPendingInviteFail,
  resendInviteStart,
  resendInviteSuccess,
  resendInviteFail,
  updateAdminStart,
  updateAdminSuccess,
  updateAdminFail,
  updateRoleId,
} = adminSlice.actions
