import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  deleteUserFail,
  deleteUserStart,
  deleteUserSuccess,
  getAllUsersFail,
  getAllUsersStart,
  getAllUsersSuccess,
  getIdentityUserFail,
  getIdentityUserSuccess,
  getMomentUserFail,
  getMomentUserStart,
  getMomentUserSuccess,
  updateUserStatusFail,
  updateUserStatusStart,
  updateUserStatusSuccess,
  getAllUserSpecificTeamsStart,
  getAllUserSpecificTeamsSuccess,
  getAllUserSpecificTeamsFail,
  getAllUserSpecificExperiencesStart,
  getAllUserSpecificExperiencesSuccess,
  getAllUserSpecificExperiencesFail,
  reviewExperienceStart,
  reviewExperienceSuccess,
  reviewExperienceFail,
  getExperienceStart,
  getExperienceSuccess,
  getExperienceFail,
  getAllInvestorUserStart,
  getAllInvestorUsersSuccess,
  getAllInvestorUsersFail,
  getIdentityUserStart,
  getProjectDetailStart,
  getProjectListingStart,
  getProjectListingSuccess,
  getProjectListingFail,
  getInvestedProjectListStart,
  getInvestedProjectListSuccess,
  getInvestedProjectListFail,
  getProjectInvestedStart,
  getProjectInvestedSuccess,
  getProjectInvestedFail,
  requestToReuploadDocStart,
  requestToReuploadDocSuccess,
  requestToReuploadDocFail,
  approveSingleKybDocStart,
  approveSingleKybDocSuccess,
  approveSingleKybDocFail,
  rejectKybSuccess,
  rejectKybFail,
  rejectKybStart,
  approveKybStart,
  approveKybFail,
  approveKybSuccess,
  requestToUpdateQuestionAnswerStart,
  requestToUpdateQuestionAnswerSuccess,
  requestToUpdateQuestionAnswerFail,
  approveKybQuestionSuccess,
  approveKybQuestionFail,
  approveKybQuestionStart,
  submitKybStart,
  submitKybSuccess,
  submitKybFail,
} from 'store/reducer/userManagement'

// Get All Users
export function* getAllUsersSaga(action) {
  yield put(getAllUsersStart())
  const { startIndex, search, itemsPerPage } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/user?type=developer`,
    successHandler: yield function* (response) {
      yield put(getAllUsersSuccess(response))
      // onSuccess?.(response)
    },
    failHandler: yield function* (response) {
      yield put(getAllUsersFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      search,
      startIndex,
      itemsPerPage,
    },
  })
}
// get All Investor User
export function* getAllInvestorUsersSaga(action) {
  yield put(getAllInvestorUserStart())
  const { startIndex, search, itemsPerPage } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/user?type=investor`,
    successHandler: yield function* (response) {
      yield put(getAllInvestorUsersSuccess(response))
      onSuccess?.(response)
    },
    failHandler: yield function* (response) {
      yield put(getAllInvestorUsersFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
      search,
      itemsPerPage,
    },
  })
}
// Update User Status
export function* updateUserStatusSaga(action) {
  yield put(updateUserStatusStart())
  const { onSuccess, onFail, userId, status } = action.payload
  const statusStr = status ? 'block' : 'unblock'
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/identity/users/${userId}/${statusStr}`,
    successHandler: yield function* (response) {
      yield put(
        updateUserStatusSuccess({
          id: userId,
          status,
        })
      )
      onSuccess?.(response)
    },
    failHandler: yield function* (response) {
      yield put(updateUserStatusFail(response))
      onFail?.(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'patch',
  })
}

// Delete User
export function* deleteUserSaga(action) {
  yield put(deleteUserStart())
  const { onSuccess, onFail, userId } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/identity/users/${userId}`,
    successHandler: yield function* (response) {
      yield put(deleteUserSuccess(response.data))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(deleteUserFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'delete',
  })
}

// Get Moment User
export function* getMomentUserSaga(action) {
  yield put(getMomentUserStart())
  const { onSuccess, onFail, userId } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/moment/users/${userId}`,
    successHandler: yield function* (response) {
      yield put(getMomentUserSuccess(response.data.items[0]))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(getMomentUserFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Get Identity User
export function* getIdentityUserSaga(action) {
  yield put(getIdentityUserStart())
  const { onSuccess, onFail, userId } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/user/detail?_user=${userId}`,
    successHandler: yield function* (response) {
      yield put(getIdentityUserSuccess(response.data))
      onSuccess()
    },
    failHandler: yield function* (response) {
      yield put(getIdentityUserFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Get All User Specific Teams
export function* getAllUserSpecificTeamsSaga(action) {
  yield put(getAllUserSpecificTeamsStart())
  const { startIndex, qString, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/users/${id}/teams?startIndex=${startIndex}&${qString}`,
    successHandler: yield function* (response) {
      yield put(getAllUserSpecificTeamsSuccess(response.data))
    },
    failHandler: yield function* (response) {
      yield put(getAllUserSpecificTeamsFail(response))
    },
    token: true,
    apiType: 'get',
    failHandlerType: 'CUSTOM',
  })
}

// Get All User Specific Experiences
export function* getAllUserSpecificExperiencesSaga(action) {
  yield put(getAllUserSpecificExperiencesStart())
  const { startIndex, qString, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/users/${id}/experiences?startIndex=${startIndex}&${qString}`,
    successHandler: yield function* (response) {
      yield put(getAllUserSpecificExperiencesSuccess(response.data))
    },
    failHandler: yield function* (response) {
      yield put(getAllUserSpecificExperiencesFail(response))
    },
    token: true,
    apiType: 'get',
    failHandlerType: 'CUSTOM',
  })
}

// Review Experience
export function* reviewExperienceSaga(action) {
  yield put(reviewExperienceStart())
  const { data, id, onSuccess, onFail } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/experiences/${id}/review`,
    successHandler: yield function* (response) {
      yield put(reviewExperienceSuccess(response?.data?.message))
      data.reviewStatus === 'Approved'
        ? openNotification('success', 'Experience approved successfully')
        : openNotification('success', 'Experience rejected successfully')
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(reviewExperienceFail(response))
      openNotification('danger', response)
      onFail?.()
    },
    token: true,
    payload: data,
    apiType: 'patch',
    failHandlerType: 'CUSTOM',
  })
}

// Get Experience
export function* getExperienceSaga(action) {
  yield put(getExperienceStart())
  const { onSuccess, onFail, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/management/studio-team/experiences/${id}`,
    successHandler: yield function* (response) {
      yield put(getExperienceSuccess(response.data.items[0]))
      onSuccess?.(response.data)
    },
    failHandler: yield function* (response) {
      yield put(getExperienceFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

// Get Project Listing for specific user
export function* getProjectListingSaga(action) {
  yield put(getProjectListingStart())
  const { id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/user/project?_user=${id}&startIndex=1&itemsPerPage=10&type=developer`,
    successHandler: yield function* (response) {
      yield put(getProjectListingSuccess(response.data))
    },
    failHandler: yield function* (response) {
      yield put(getProjectListingFail(response))
    },
    token: true,
    apiType: 'get',
    failHandlerType: 'CUSTOM',
  })
}

// Get Project Listing for specific user
export function* getInvestedProjectListSaga(action) {
  yield put(getInvestedProjectListStart())
  const { id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/user/invested-projects?_user=${id}&startIndex=1&itemsPerPage=10&type=developer`,
    successHandler: yield function* (response) {
      // console.log(response, 'projectListing saga')
      yield put(getInvestedProjectListSuccess(response.data))
    },
    failHandler: yield function* (response) {
      yield put(getInvestedProjectListFail(response))
    },
    token: true,
    apiType: 'get',
    failHandlerType: 'CUSTOM',
  })
}

// Get Project Invested for investor
export function* getProjectInvestedSaga(action) {
  yield put(getProjectInvestedStart())
  // const { startIndex, qString, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    // endpoint: `/admin/user/project?_user=${userId}&startIndex=1&itemsPerPage=10&type=developer`,
    successHandler: yield function* (response) {
      yield put(getProjectInvestedSuccess(response.data))
    },
    failHandler: yield function* (response) {
      yield put(getProjectInvestedFail(response))
    },
    token: true,
    apiType: 'get',
    failHandlerType: 'CUSTOM',
  })
}

// requestToReuploadDoc Status
export function* requestToReuploadDocSaga(action) {
  yield put(requestToReuploadDocStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/request-to-reupload`,
    successHandler: yield function* (response) {
      yield put(requestToReuploadDocSuccess({}))
      onSuccess?.()
      openNotification('success', response?.message)
    },
    failHandler: yield function* (response) {
      yield put(requestToReuploadDocFail(response))
      onFail?.(response)
      openNotification('danger', response?.message)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// approveSingleKybDoc Status
export function* approveSingleKybDocSaga(action) {
  yield put(approveSingleKybDocStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/approve-single-document`,
    successHandler: yield function* (response) {
      yield put(approveSingleKybDocSuccess({}))
      onSuccess?.()
      openNotification('success', response?.message)
    },
    failHandler: yield function* (response) {
      yield put(approveSingleKybDocFail(response))
      onFail?.(response)
      openNotification('danger', response?.message)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// submitKyb Status
export function* submitKybSaga(action) {
  yield put(submitKybStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/submit-request-changes`,
    successHandler: yield function* (response) {
      yield put(submitKybSuccess({}))
      onSuccess?.()
      openNotification('success', response?.message)
    },
    failHandler: yield function* (response) {
      yield put(submitKybFail(response))
      onFail?.(response)
      openNotification('danger', response?.message)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// approveSingleKybDoc Status
export function* approveKybQuestionSaga(action) {
  yield put(approveKybQuestionStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/approve-qna`,
    successHandler: yield function* (response) {
      yield put(approveKybQuestionSuccess({}))
      onSuccess?.()
      openNotification('success', response?.message)
    },
    failHandler: yield function* (response) {
      yield put(approveKybQuestionFail(response))
      onFail?.()
      openNotification('danger', response?.message)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// rejectKyb Status
export function* rejectKybSaga(action) {
  yield put(rejectKybStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/reject-document`,
    successHandler: yield function* (response) {
      yield put(rejectKybSuccess({}))
      onSuccess?.()
      openNotification('success', response?.message)
    },
    failHandler: yield function* (response) {
      yield put(rejectKybFail(response))
      onFail?.(response)
      openNotification('danger', response?.message)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// approveKyb Status
export function* approveKybSaga(action) {
  yield put(approveKybStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/approve-document`,
    successHandler: yield function* (response) {
      yield put(approveKybSuccess({}))
      onSuccess?.(response)
      openNotification('success', response)
    },
    failHandler: yield function* (response) {
      yield put(approveKybFail(response))
      onFail?.(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

// requestToUpdateQuestionAnswer Status
export function* requestToUpdateQuestionAnswerSaga(action) {
  yield put(requestToUpdateQuestionAnswerStart())
  const { onSuccess, onFail, data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/request-to-update-answer`,
    successHandler: yield function* (response) {
      yield put(requestToUpdateQuestionAnswerSuccess({}))
      onSuccess?.(response)
      openNotification('success', response)
    },
    failHandler: yield function* (response) {
      yield put(requestToUpdateQuestionAnswerFail(response))
      onFail?.(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
