import { put } from 'redux-saga/effects'

import { BASE_URL } from 'constants/envs.constant'
import {
  fetchUserFail,
  fetchUserStart,
  fetchUserSuccess,
  updatePasswordFail,
  updatePasswordStart,
  updatePasswordSuccess,
  updateUserFail,
  updateUserStart,
  updateUserSuccess,
} from 'store/reducer/auth/userSlice'
import errorHandler from 'utils/errorHandler'
import { openNotification } from 'utils/showToast'

export function* fetchUserSaga(action) {
  yield put(fetchUserStart())
  const { onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/admin/profile/me',
    successHandler: yield function* (response) {
      yield put(fetchUserSuccess(response?.data))
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(fetchUserFail(response.error))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* updateUserSaga(action) {
  yield put(updateUserStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/me',
    successHandler: yield function* (response) {
      yield put(updateUserSuccess(response?.data?.items[0]))
      openNotification('success', response?.data?.message)
      onSuccess?.(response?.data.items[0])
    },
    failHandler: yield function* (response) {
      yield put(updateUserFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

export function* updatePasswordSaga(action) {
  yield put(updatePasswordStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: '/admin/profile/change-password',
    successHandler: yield function* (response) {
      yield put(updatePasswordSuccess(response?.data))
      openNotification('success', response?.message)
      onSuccess?.()
    },
    failHandler: yield function* (response) {
      yield put(updatePasswordFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
