import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  getAllNotificationFail,
  getAllNotificationStart,
  getAllNotificationSuccess,
  markAllNotificationFail,
  markAllNotificationStart,
  markAllNotificationSuccess,
  markSingleNotificationFail,
  markSingleNotificationStart,
  markSingleNotificationSuccess,
} from 'store/reducer/notification'

export function* getAllNotifcationSaga(action) {
  yield put(getAllNotificationStart())
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/notification`,
    successHandler: yield function* (response) {
      yield put(getAllNotificationSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getAllNotificationFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}
export function* markAllNotificationSaga(action) {
  yield put(markAllNotificationStart())
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/notification/`,
    successHandler: yield function* (response) {
      yield put(markAllNotificationSuccess(response.data))
      //onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(markAllNotificationFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    // payload: data,
    apiType: 'patch',
  })
}

export function* markSingleNotificationSaga(action) {
  yield put(markSingleNotificationStart())
  const { _notification } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/notification/`,
    successHandler: yield function* (response) {
      yield put(markSingleNotificationSuccess(response.data))
      //onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(markSingleNotificationFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    // payload: data,
    apiType: 'patch',
    params: {
      _notification,
    },
  })
}
