import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  getAllDraftStart,
  getAllDraftFail,
  getAllDraftSuccess,
  getAllPublishedStart,
  getAllPublishedSuccess,
  getAllPublishedFail,
  getMessageHistoryStart,
  getMessageHistorySuccess,
  getMessageHistoryFail,
  getReplyWithMessageStart,
  getReplyWithMessageSuccess,
  getReplyWithMessageFail,
  submitReviewStart,
  submitReviewSuccess,
  submitReviewFail,
  approvefccStart,
  approvefccSuccess,
  approvefccFail,
  rejectfccStart,
  rejectfccSucess,
  rejectfccFail,
  approveDesignSuccess,
  approveDesignStart,
  approveDesignFail,
  approveDescriptionStart,
  approveDescriptionSuccess,
  approveDescriptionnFail,
  approvePublicConsulationStart,
  approvePublicConsulationSuccess,
  approvePublicConsulationFail,
  approveDescriptionFinalStart,
  approveDescriptionFinalSuccess,
  approveDescriptionFinalFail,
  requestChangesStart,
  requestChangesSuccess,
  requestChangesFail,
  getProjectDetailStart,
  getProjectDetailSuccess,
  approveMonitoringStart,
  approveMonitoringSuccess,
  approveMonitoringlFail,
  getProjectUpdatesDetaisStart,
  getProjectUpdatesDetailsSuccess,
  getProjectUpdatesDetailsFail,
  closePublicConsulationFail,
  closePublicConsulationSuccess,
  closePublicConsulationStart,
  updateProjectIdStart,
  updateProjectIdSuccess,
  updateProjectIdFail,
  getProjectFccHistoryStart,
  getProjectFccHistorySuccess,
  getProjectFccHistoryFail,
  getProjectVccHistoryStart,
  getProjectVccHistorySuccess,
  getProjectVccHistoryFail,
  getProjectVccListingStart,
  getProjectVccListingSuccess,
  getProjectVccListingFail,
  getKybQuestionsStart,
  getKybQuestionsSuccess,
  getKybQuestionsFail,
  getProjectDetailFail,
  updateRegistryLinkStart,
  updateRegistryLinkSuccess,
  updateRegistryLinkFail,
  getVintageYearsStart,
  getVintageYearsFail,
  getVintageYearsSuccess,
  getMonitoringVccStart,
  getMonitoringVccFail,
  getMonitoringVccSuccess,
  updateChangeActiveFail,
  updateChangeActiveStart,
  updateChangeActiveSuccess,
} from 'store/reducer/projectManagement'

export function* getAllDraftSaga(action) {
  yield put(getAllDraftStart())
  const {
    onSuccess,
    status,
    standard,
    search,
    startDate,
    endDate,
    startIndex,
    sortBy,
    sortValue,
  } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project?type=draft`,
    successHandler: yield function* (response) {
      yield put(getAllDraftSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getAllDraftFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      status,
      standard,
      search,
      startDate,
      endDate,
      startIndex,
      sortBy,
      sortValue,
    },
  })
}
export function* getAllPublishedSaga(action) {
  yield put(getAllPublishedStart())
  const {
    onSuccess,
    status,
    standard,
    search,
    startDate,
    endDate,
    startIndex,
    sortBy,
    sortValue,
  } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project?type=registered`,
    successHandler: yield function* (response) {
      yield put(getAllPublishedSuccess(response))
      // onSuccess?.(response.data)
      // openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getAllPublishedFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      status,
      standard,
      search,
      startDate,
      endDate,
      startIndex,
      sortBy,
      sortValue,
    },
  })
}
export function* getProjectDetailsSaga(action) {
  yield put(getProjectDetailStart())
  const { id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/details/${id}`,
    successHandler: yield function* (response) {
      yield put(getProjectDetailSuccess(response.data))
      //onSuccess?.(response.data)
      // openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getProjectDetailFail(response))
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}
export function* getMessageHistorySaga(action) {
  yield put(getMessageHistoryStart())
  const { onSuccess, id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/thread-history?_project=${id}`,
    successHandler: yield function* (response) {
      yield put(getMessageHistorySuccess(response.data))
      onSuccess?.(response.data)
      // openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getMessageHistoryFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getProjectFccHistorySaga(action) {
  yield put(getProjectFccHistoryStart())
  const { onSuccess, id, startIndex = 0 } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/fcc-minting-history?_project=${id}`,
    successHandler: yield function* (response) {
      yield put(getProjectFccHistorySuccess(response.data))
      onSuccess?.(response.data)
      // openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getProjectFccHistoryFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
    },
  })
}

export function* getProjectVccHistorySaga(action) {
  yield put(getProjectVccHistoryStart())
  const { onSuccess, id, startIndex = 0 } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/vcc-minting-history?_project=${id}`,
    successHandler: yield function* (response) {
      yield put(getProjectVccHistorySuccess(response.data))
      onSuccess?.(response.data)
      // openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getProjectVccHistoryFail(response))
      //onFail(response)
      // openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
    },
  })
}

export function* getProjectVccListingSaga(action) {
  yield put(getProjectVccListingStart())
  const { onSuccess, id, startIndex = 0, _tokenList, onError } = action.payload
  // console.log({ _tokenList })
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/vcc-listing-history?_project=${id}`,
    successHandler: yield function* (response) {
      yield put(getProjectVccListingSuccess(response.data))
      onSuccess?.(response.data)
      // openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getProjectVccListingFail(response))
      //onFail(response)
      onError?.()
      // openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
      _tokenList,
    },
  })
}

export function* getReplyWithMessageSaga(action) {
  yield put(getReplyWithMessageStart())
  const { data } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/thread/reply`,
    successHandler: yield function* (response) {
      yield put(getReplyWithMessageSuccess(response.data))
      //onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getReplyWithMessageFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

export function* submitReviewSaga(action) {
  yield put(submitReviewStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/submit-review`,
    successHandler: yield function* (response) {
      yield put(submitReviewSuccess(response.data))
      onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(submitReviewFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

export function* fccRequestApproveSaga(action) {
  yield put(approvefccStart())
  const { data, onSuccess, onClose, setHardApprove } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/update-minting-request`,
    successHandler: yield function* (response) {
      yield put(approvefccSuccess(response))
      onSuccess?.(response)
      onClose?.()
      setHardApprove?.(true)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(approvefccFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

export function* fccRequestRejectSaga(action) {
  yield put(rejectfccStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/update-minting-request`,
    successHandler: yield function* (response) {
      yield put(rejectfccSucess(response.message))
      onSuccess?.(response)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(rejectfccFail(response))
      onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
export function* verifcationCreditIssuSaga(action) {
  yield put(submitReviewStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/issue-credits`,
    successHandler: yield function* (response) {
      yield put(submitReviewSuccess(response.data))
      onSuccess?.(response.data)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(submitReviewFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
export function* approveDesignSaga(action) {
  yield put(approveDesignStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/approve-design`,
    successHandler: yield function* (response) {
      yield put(approveDesignSuccess(response.message))
      onSuccess?.(response)
      openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(approveDesignFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
export function* approvedescriptionSaga(action) {
  yield put(approveDescriptionStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/approve-design`,
    successHandler: yield function* (response) {
      yield put(approveDescriptionSuccess(response.message))
      onSuccess?.(response)
      openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(approveDescriptionnFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
export function* approvePublicCosulationSaga(action) {
  yield put(approvePublicConsulationStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/approve-design`,
    successHandler: yield function* (response) {
      yield put(approvePublicConsulationSuccess(response.message))
      onSuccess?.(response)
      openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(approvePublicConsulationFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

export function* closePublicCosultationSaga(action) {
  yield put(closePublicConsulationStart())
  const { id, onCloseSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/${id}/close-public-consultation`,
    successHandler: yield function* (response) {
      yield put(closePublicConsulationSuccess(response.message))
      onCloseSuccess?.(response)
      openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(closePublicConsulationFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'post',
  })
}
export function* approvedescrptionFinalizationSaga(action) {
  yield put(approveDescriptionFinalStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/approve-design`,
    successHandler: yield function* (response) {
      yield put(approveDescriptionFinalSuccess(response.message))
      onSuccess?.(response.message)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(approveDescriptionFinalFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}

export function* updateProjectIdSaga(action) {
  yield put(updateProjectIdStart())
  const { data, _project } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/${_project}/update-project-id`,
    successHandler: yield function* (response) {
      yield put(updateProjectIdSuccess(response.message))
      //onSuccess?.(response.message)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(updateProjectIdFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'post',
  })
}

export function* updateRegistryLinkSaga(action) {
  yield put(updateRegistryLinkStart())
  const { data, _project } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/${_project}/update-project-registry-link`,
    successHandler: yield function* (response) {
      yield put(updateRegistryLinkSuccess(response.message))
      //onSuccess?.(response.message)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(updateRegistryLinkFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'post',
  })
}

export function* updateChangeActiveSaga(action) {
  yield put(updateChangeActiveStart())
  const { data, _project } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/${_project}/change-active`,
    successHandler: yield function* (response) {
      yield put(updateChangeActiveSuccess(response.message))
      //onSuccess?.(response.message)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(updateChangeActiveFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'post',
  })
}

export function* requestForChangesSaga(action) {
  yield put(requestChangesStart())
  const { data, onSuccess } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/request-changes`,
    successHandler: yield function* (response) {
      yield put(requestChangesSuccess(response.message))
      onSuccess?.(response)
      openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(requestChangesFail(response))
      // onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    payload: data,
    apiType: 'patch',
  })
}
export function* approveMonitoringPhaseSaga(action) {
  yield put(approveMonitoringStart())
  const { id, onSuccess, vintageYear } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/approve-monitoring?_project=${id}&vintageYear=${vintageYear}`,
    successHandler: yield function* (response) {
      yield put(approveMonitoringSuccess(response.message))
      onSuccess?.(response)
      openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(approveMonitoringlFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'patch',
  })
}

export function* getConversationsDetailsSaga(action) {
  yield put(getProjectUpdatesDetaisStart())
  const { id, conversation_id } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/conversations/${id}/chats/${conversation_id}`,
    successHandler: yield function* (response) {
      yield put(getProjectUpdatesDetailsSuccess(response.data))
      // onSuccess?.(response)
      openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(getProjectUpdatesDetailsFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getKybQuestionsSaga(action) {
  yield put(getKybQuestionsStart())
  const { userId } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/kyb/questions?_user=${userId}`,
    successHandler: yield function* (response) {
      yield put(getKybQuestionsSuccess(response.data))
      // onSuccess?.(response)
      // openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(getKybQuestionsFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getVintageYearsSaga(action) {
  yield put(getVintageYearsStart())
  const { _project } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/${_project}/get-project-vintage-years`,
    successHandler: yield function* (response) {
      yield put(getVintageYearsSuccess(response.data))
      // onSuccess?.(response)
      // openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(getVintageYearsFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}

export function* getMonitoringVccSaga(action) {
  yield put(getMonitoringVccStart())
  const { _project, vintageYear } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/project/${_project}/get-monitoring-vcc?vintageYear=${vintageYear}`,
    successHandler: yield function* (response) {
      yield put(getMonitoringVccSuccess(response.data))
      // onSuccess?.(response)
      // openNotification('success', response.message)
      return
    },
    failHandler: yield function* (response) {
      yield put(getMonitoringVccFail(response))
      //onFail(response)
      // openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}
