import React, { useState } from 'react'
import { Input } from 'components/ui'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'
import { FcCheckmark } from 'react-icons/fc'

const PasswordInput = (props) => {
  const { onVisibleChange, tick, ...rest } = props

  const [pwInputType, setPwInputType] = useState('password')

  const onPasswordVisibleClick = (e) => {
    e.preventDefault()
    const nextValue = pwInputType === 'password' ? 'text' : 'password'
    setPwInputType(nextValue)
    onVisibleChange?.(nextValue === 'text')
  }

  return (
    <Input
      {...rest}
      type={pwInputType}
      suffix={
        <>
          {tick && <FcCheckmark className="text-xl mr-1 pb-0.5" />}
          <span
            className="cursor-pointer text-xl"
            onClick={(e) => onPasswordVisibleClick(e)}
          >
            {pwInputType === 'password' ? (
              <HiOutlineEyeOff />
            ) : (
              <HiOutlineEye />
            )}
          </span>
        </>
      }
    />
  )
}

export default PasswordInput
