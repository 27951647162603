import React from 'react'
import { Notification, toast } from 'components/ui'

export const openNotification = (
  type,
  message,
  duration,
  placement,
  closable = false
) => {
  const toastProps = {
    ...(type ? { type } : {}),
    ...(duration ? { duration } : {}),
  }

  toast.push(
    <Notification {...toastProps} closable={closable}>
      {message}
    </Notification>,
    { placement }
  )
}
