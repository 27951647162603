import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'

import rootSaga from './saga'
import rootReducer from './reducer/rootReducer'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

// set up later
const persistConfig = {
  key: 'root',
  keyPrefix: '',
  storage,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV === 'development',
})

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)
