import { createAction } from '@reduxjs/toolkit'

export const getAllExperiencesSagaAction = createAction(
  'GET_ALL_EXPERIENCES_SAGA'
)
export const updateExperienceStatusSagaAction = createAction(
  'UPDATE_EXPERIENCE_STATUS_SAGA'
)
export const deleteExperienceSagaAction = createAction('DELETE_EXPERIENCE_SAGA')
export const getExperienceSagaAction = createAction('GET_EXPERIENCE_SAGA')

export const getAllTemplateSagaAction = createAction('GET_ALL_TEMPLATE_SAGA')
export const createTeplateSagaAction = createAction('CREATE_TEMPLATE_SAGA')
export const viewTemplateSagaAction = createAction('VIEW_TEMPLATE_SAGA')
export const deleteTemplateSagaAction = createAction('DELETE_TEMPLATE_SAGA')

export const getAllCategorySagaAction = createAction('GET_ALL_CATEGORY_SAGA')

export const updateTemplateStatusSagaAction = createAction('UPDATE_TEMPLATE_STATUS_SAGA')
