import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getTotalProjectCount: false,
    getLoacationWiseProject: false,
    getTopProjects: false,
    getTopInvestors: false,
    getSalesGraphData: false,
  },
  allProjectCount: {},
  getAllLocationWiseProject: {},
  getTopProjects: {},
  getTopInvestors: {},
  getSalesGraphData: [],
}
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getProjectCountStart: (state) => {
      state.isLoading.getTotalProjectCount = true
    },

    getProjectCountSuccess: (state, { payload }) => {
      state.isLoading.getTotalProjectCount = false
      state.allProjectCount = payload
    },
    getProjectCountFail: (state) => {
      state.isLoading.getTotalProjectCount = false
    },
    getLocationWiseProjectStart: (state) => {
      state.isLoading.getLoacationWiseProject = true
    },
    getLoacationWiseProjectSuccess: (state, { payload }) => {
      state.isLoading.getLoacationWiseProject = false
      state.getAllLocationWiseProject = payload.data
    },
    getLoacationWiseProjectFail: (state) => {
      state.isLoading.getLoacationWiseProject = false
    },
    getTopProjectsStart: (state) => {
      state.isLoading.getTopProjects = true
    },
    getTopProjectsSuccess: (state, { payload }) => {
      state.isLoading.getTopProjects = false
      state.getTopProjects = payload.data
    },
    getTopProjectsFail: (state) => {
      state.isLoading.getTopProjects = false
    },
    getTopInvestorsStart: (state) => {
      state.isLoading.getTopInvestors = true
    },
    getTopInvestorsSuccess: (state, { payload }) => {
      state.isLoading.getTopInvestors = false
      state.getTopInvestors = payload.data
    },
    getTopInvestorsFail: (state) => {
      state.isLoading.getTopInvestors = false
    },
    getSalesGraphDataStart: (state) => {
      state.isLoading.getSalesGraphData = true
    },
    getSalesGraphDataSuccess: (state, { payload }) => {
      state.isLoading.getSalesGraphData = false
      state.getSalesGraphData = payload.data
    },
    getSalesGraphDataFail: (state) => {
      state.isLoading.getSalesGraphData = false
    },
  },
})
export default dashboardSlice.reducer
export const {
  getLoacationWiseProjectFail,
  getLoacationWiseProjectSuccess,
  getLocationWiseProjectStart,
  getProjectCountFail,
  getProjectCountStart,
  getProjectCountSuccess,
  getTopProjectsStart,
  getTopProjectsSuccess,
  getTopProjectsFail,
  getTopInvestorsStart,
  getTopInvestorsSuccess,
  getTopInvestorsFail,
  getSalesGraphDataStart,
  getSalesGraphDataSuccess,
  getSalesGraphDataFail,
} = dashboardSlice.actions
