const initialState = {
  selectedVintageYear: '', // Initially empty
}

const vintageYear = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_VINTAGE_YEAR':
      return {
        ...state,
        selectedVintageYear: action.payload,
      }
    default:
      return state
  }
}

export default vintageYear
