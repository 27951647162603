import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllExperiences: false,
    updateExperienceStatus: false,
    deleteExperience: false,
    experience: false,
    getAllTemplate: false,
    createTemplate: false,
    viewTemplate: false,
    deleteTemplate: false,
    getAllCategory: false,
  },
  templateData: {},
  allExperiences: {},
  categories: {},
  experience: {},
  templates: {},
  updateExperienceMessage: '',
}

export const experienceManagementSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    // Get All Experiences
    getAllExperiencesStart: (state) => {
      state.isLoading.getAllExperiences = true
    },
    getAllExperiencesSuccess: (state, { payload }) => {
      state.isLoading.getAllExperiences = false
      state.allExperiences = payload
    },
    getAllExperiencesFail: (state) => {
      state.isLoading.getAllExperiences = false
    },
    // Get All Category
    getAllCategoryStart: (state) => {
      state.isLoading.getAllCategory = true
    },
    getAllCategorySuccess: (state, { payload }) => {
      state.isLoading.getAllCategory = false
      state.categories = payload
    },
    getAllCategoryFail: (state) => {
      state.isLoading.getAllCategory = false
    },
    // Get All Templates
    getAllTemplateStart: (state) => {
      state.isLoading.getAllTemplate = true
    },
    getAllTemplateSuccess: (state, { payload }) => {
      state.isLoading.getAllTemplate = false
      state.templates = payload
    },
    getAllTemplateFail: (state) => {
      state.isLoading.getAllTemplate = false
    },
    // Uptate Experience Status
    updateExperienceStatusStart: (state) => {
      state.isLoading.updateExperienceStatus = true
    },
    updateExperienceStatusSuccess: (state, { payload }) => {
      state.isLoading.updateExperienceStatus = false
      state.experience.updateExperienceMessage = payload.message
    },
    updateExperienceStatusFail: (state) => {
      state.isLoading.updateExperienceStatus = false
    },
    // Update Template Status
    updateTemplateStatusStart: (state) => {
      state.isLoading.updateTemplateStatus = true
    },
    updateTemplateStatusSuccess: (state, { payload }) => {
      state.isLoading.updateTemplateStatus = false
   
      const newData = state.templates.items.map((item) =>
        item._id === payload.id
          ? { ...item, isTemporarilyDisabled: !item.isTemporarilyDisabled }
          : item
      )
      state.templates.items = newData
    },
    updateTemplateStatusFail: (state) => {
      state.isLoading.updateTemplateStatus = false
    },
    // Create Template
    createTemplateStart: (state) => {
      state.isLoading.createTemplate = true
    },
    createTemplateSuccess: (state, { payload }) => {
      state.isLoading.createTemplate = false
    },
    createTemplateFail: (state) => {
      state.isLoading.createTemplate = false
    },
    // View Template
    viewTemplateStart: (state) => {
      state.isLoading.viewTemplate = true
    },
    viewTemplateSuccess: (state, { payload }) => {
      state.isLoading.viewTemplate = false
      state.templateData = payload?.items?.[0]
    },
    viewTemplateFail: (state) => {
      state.isLoading.viewTemplate = false
    },
    // Delete Template
    deleteTemplateStart: (state) => {
      state.isLoading.deleteTemplate = true
    },
    deleteTemplateSuccess: (state) => {
      state.isLoading.deleteTemplate = false
    },
    deleteTemplateFail: (state) => {
      state.isLoading.deleteTemplate = false
    },
    // Delete Experience
    deleteExperienceStart: (state) => {
      state.isLoading.deleteExperience = true
    },
    deleteExperienceSuccess: (state) => {
      state.isLoading.deleteExperience = false
    },
    deleteExperienceFail: (state) => {
      state.isLoading.deleteExperience = false
    },

    // Get Experience Data
    getExperienceStart: (state) => {
      state.isLoading.experience = true
    },
    getExperienceSuccess: (state, { payload }) => {
      state.isLoading.experience = false
      state.experience = payload
    },
    getExperienceFail: (state) => {
      state.isLoading.experience = false
    },
  },
})

export default experienceManagementSlice.reducer
export const {
  getAllExperiencesStart,
  getAllExperiencesSuccess,
  getAllExperiencesFail,
  updateExperienceStatusStart,
  updateExperienceStatusSuccess,
  updateExperienceStatusFail,
  deleteExperienceStart,
  deleteExperienceSuccess,
  deleteExperienceFail,
  reviewExperienceStart,
  reviewExperienceSuccess,
  reviewExperienceFail,
  getExperienceStart,
  getExperienceSuccess,
  getExperienceFail,
  getAllTemplateFail,
  getAllTemplateSuccess,
  getAllTemplateStart,
  createTemplateStart,
  createTemplateSuccess,
  createTemplateFail,
  viewTemplateStart,
  viewTemplateSuccess,
  viewTemplateFail,
  deleteTemplateStart,
  deleteTemplateSuccess,
  deleteTemplateFail,
  getAllCategoryFail,
  getAllCategoryStart,
  getAllCategorySuccess,
  updateTemplateStatusStart,
  updateTemplateStatusSuccess,
  updateTemplateStatusFail,
} = experienceManagementSlice.actions
