import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import { openNotification } from 'utils/showToast'
import {
  getLoacationWiseProjectFail,
  getLoacationWiseProjectSuccess,
  getLocationWiseProjectStart,
  getProjectCountFail,
  getProjectCountStart,
  getProjectCountSuccess,
  getTopProjectsStart,
  getTopProjectsSuccess,
  getTopProjectsFail,
  getTopInvestorsStart,
  getTopInvestorsSuccess,
  getTopInvestorsFail,
  getSalesGraphDataStart,
  getSalesGraphDataSuccess,
  getSalesGraphDataFail,
} from 'store/reducer/dashboard'

export function* getProjectCountSaga(action) {
  yield put(getProjectCountStart())
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/dashboard/counts`,
    successHandler: yield function* (response) {
      yield put(getProjectCountSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getProjectCountFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
  })
}
export function* getLocationwiseSaga(action) {
  const { search } = action.payload
  yield put(getLocationWiseProjectStart())
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/dashboard/locationwise-projects`,
    successHandler: yield function* (response) {
      yield put(getLoacationWiseProjectSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getLoacationWiseProjectFail(response))
      //onFail(response)
      openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    params: {
      search,
    },
    token: true,
    apiType: 'get',
  })
}
export function* getTopInvestorsSaga(action) {
  yield put(getTopInvestorsStart())
  const { startIndex, itemPerPage } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/dashboard/top-investors`,
    successHandler: yield function* (response) {
      yield put(getTopInvestorsSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getTopInvestorsFail(response))
      //onFail(response)
      // openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
      itemPerPage,
    },
  })
}
export function* getTopProjectsSaga(action) {
  yield put(getTopProjectsStart())
  const { startIndex, itemPerPage } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/dashboard/top-projects`,
    successHandler: yield function* (response) {
      yield put(getTopProjectsSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getTopProjectsFail(response))
      //onFail(response)
      // openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startIndex,
      itemPerPage,
    },
  })
}
export function* getSalesGraphDataSaga(action) {
  yield put(getSalesGraphDataStart())
  const { startDate, endDate } = action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/dashboard/sales-graph-data`,
    successHandler: yield function* (response) {
      yield put(getSalesGraphDataSuccess(response))
      //onSuccess?.(response.data)
      //openNotification('success', response.message)
    },
    failHandler: yield function* (response) {
      yield put(getSalesGraphDataFail(response))
      //onFail(response)
      // openNotification('danger', response)
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      startDate,
      endDate,
    },
  })
}
