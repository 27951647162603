import { all, takeLatest } from 'redux-saga/effects'

// Saga Action Imports
import {
  authOTPVerificationSagaAction,
  codeVeriRequestSagaAction,
  codeVeriVerifySagaAction,
  getTokenDetailSagaAction,
  loginSagaAction,
  logoutSagaAction,
  registerSagaAction,
  resetPasswordSagaAction,
  updateSettingUpdateSagaAction,
} from 'store/sagaActions/auth/auth'
import {
  fetchUserSagaAction,
  updatePasswordSagaAction,
  updateUserSagaAction,
} from 'store/sagaActions/auth/user'
import {
  activityLogsSagaAction,
  createAdminSagaAction,
  deleteAdminSagaAction,
  disabledAdminSagaAction,
  getAdminSagaAction,
  getAllAdminsSagaAction,
  getAllPendingInviteSagaAction,
  getPendingInviteSagaAction,
  resendInviteSagaAction,
  updateAdminSagaAction,
} from 'store/sagaActions/admin/admin'
import {
  createRoleSagaAction,
  deleteRoleSagaAction,
  getAclEndpointSagaAction,
  getAllRolesSagaAction,
  getRoleSagaAction,
  updateRoleSagaAction,
} from 'store/sagaActions/role/role'
import {
  deleteUserSagaAction,
  getAllUsersSagaAction,
  getAllInvestorUsersSagaAction,
  getIdentityeUserSagaAction,
  getMomentUserSagaAction,
  updateUserStatusSagaAction,
  getAllUserSpecificTeamsSagaAction,
  getAllUserSpecificExperiencesSagaAction,
  getExperienceSagaAction,
  reviewExperienceSagaAction,
  getProjectInvestedSagaAction,
  getProjectListingSagaAction,
  getInvestedProjectListSagaAction,
  requestToUpdateQuestionAnswerSagaAction,
  approveKybSagaAction,
  rejectKybSagaAction,
  approveSingleKybDocSagaAction,
  requestToReuploadDocSagaAction,
  getKybQuestionsSagaAction,
  getVintageYearsSagaAction,
  getMonitoringVccSagaAction,
  approveKybQuestionSagaAction,
  submitKybSagaAction,
} from 'store/sagaActions/userManagement/userManagement'

// Saga Imports
import {
  authOTPVerificationSaga,
  codeVerificationRequestSaga,
  codeVerificationVerifySaga,
  loginSaga,
  logoutSaga,
  registerSaga,
  resetPasswordSaga,
  tokenDetailSaga,
  updateSettingUpdateSaga,
} from './auth/auth'
import { fetchUserSaga, updatePasswordSaga, updateUserSaga } from './auth/user'
import {
  createAdminSaga,
  deleteAdminSaga,
  disableAdminSaga,
  getActivityLogsSaga,
  getAllAdminsSaga,
  getAdminSaga,
  getAllPendingInvitesSaga,
  getPendingInviteSaga,
  resendInviteSaga,
  updateAdminSaga,
} from './admin/admin'
import {
  createRoleSaga,
  deleteRoleSaga,
  getAclEndpointsSaga,
  getAllRolesSaga,
  getRoleSaga,
  updateRoleSaga,
} from './role/role'
import {
  deleteUserSaga,
  getAllUsersSaga,
  getAllInvestorUsersSaga,
  getIdentityUserSaga,
  getMomentUserSaga,
  updateUserStatusSaga,
  getAllUserSpecificTeamsSaga,
  getAllUserSpecificExperiencesSaga,
  getExperienceSaga,
  reviewExperienceSaga,
  getProjectDetailSaga,
  getProjectListingSaga,
  getProjectInvestedSaga,
  getInvestedProjectListSaga,
  requestToUpdateQuestionAnswerSaga,
  approveKybSaga,
  rejectKybSaga,
  approveSingleKybDocSaga,
  requestToReuploadDocSaga,
  approveKybQuestionSaga,
  submitKybSaga,
} from './userManagement/userManagement'
import {
  createTeplateSagaAction,
  deleteExperienceSagaAction,
  deleteTemplateSagaAction,
  getAllCategorySagaAction,
  getAllExperiencesSagaAction,
  getAllTemplateSagaAction,
  updateExperienceStatusSagaAction,
  updateTemplateStatusSagaAction,
  viewTemplateSagaAction,
} from 'store/sagaActions/experienceManagement/experienceManagement'
import {
  deleteExperienceSaga,
  getAllExperiencesSaga,
  updateExperienceStatusSaga,
  getAllTemplateSaga,
  createTemplateSaga,
  viewTemplateSaga,
  deleteTemplateSaga,
  getAllCategorySaga,
  updateTemplateStatusSaga,
} from './experienceManagement/experienceManagement'
import {
  getAllTeamsSaga,
  getTeamMembersSaga,
  getTeamSaga,
} from './teamManagement/teamManagement'
import {
  getAllTeamsSagaAction,
  getTeamMembersSagaAction,
  getTeamSagaAction,
} from 'store/sagaActions/teamManagement/teamManagement'
import {
  approveDesignSaga,
  approveMonitoringPhase,
  approveMonitoringPhaseSaga,
  approvePublicCosulationSaga,
  closePublicCosultationSaga,
  approvedescriptionSaga,
  approvedescrptionFinalizationSaga,
  approvedesignSaga,
  fccRequestApproveSaga,
  fccRequestRejectSaga,
  getAllDraftSaga,
  getAllPublishedSaga,
  getMessageHistorySaga,
  getProjectDetailsSaga,
  getReplyWithMessageSaga,
  requestForChangesSaga,
  submitReviewSaga,
  verifcationCreditIssuSaga,
  getConversationsDetailsSaga,
  updateProjectIdSaga,
  updateRegistryLinkSaga,
  updateChangeActiveSaga,
  getProjectFccHistorySaga,
  getProjectVccHistorySaga,
  getProjectVccListingSaga,
  getKybQuestionsSaga,
  getVintageYearsSaga,
  getMonitoringVccSaga,
} from './projectManagement/projectManagement'
import {
  approveDescriptionFinalizationSagaAction,
  approveDescriptionSagaAction,
  approveDesignSagaAction,
  approveMonitoringSagaAction,
  approvePublicConsultationSagaAction,
  closePublicConsultationSagaAction,
  fccMintingRequestApprove,
  fccMintingRequestApproveSagaAction,
  fccMintingRequestRejectSagaAction,
  getAllDraftSagaAction,
  getAllPublishedSagaAction,
  getMessageHistorySagaAction,
  getProjectDetailSagaAction,
  getReplyWithMessageSagaAction,
  requestChangeSagaAction,
  submitReviewSagaAction,
  verificationCreditIssuancSagaAction,
  getConversationsAction,
  updateProjectIdSagaAction,
  updateRegistryLinkSagaAction,
  updateChangeActiveSagaAction,
  getProjectFccHistorySagaAction,
  getProjectVccHistorySagaAction,
  getProjectVccListingSagaAction,
} from 'store/sagaActions/projectManagement/projectManagement'
import {
  UpdateContentSagaAction,
  getAllContentSagaAction,
} from 'store/sagaActions/contentManagement/contentManagement'
import {
  getAllContentSaga,
  updateCmsSaga,
} from './contentManagementSystem/contentManagementSystem'
import {
  getAllHistorySagaAction,
  getAllVariableSagaAction,
  updateVariableSagaAction,
} from 'store/sagaActions/platformVariable/platformVariable'
import {
  getAllHistorySaga,
  getAllVariableSaga,
  updateVariableSaga,
} from './platformVariable/platformVariable'
import {
  getAllNotificationSagaAction,
  markAllNotificationSagaAction,
  markSingleNotificationSagaAction,
} from 'store/sagaActions/notification/notification'
import {
  getAllNotifcationSaga,
  markAllNotificationSaga,
  markSingleNotificationSaga,
} from './notification/notification'
import {
  getTopProjectsAction,
  getLocationWiseProjectSagaAction,
  getTopInvestorsAction,
  getProjectCountSagaAction,
  getSalesGraphDataAction,
} from 'store/sagaActions/dashBoard/dashBoard'
import {
  getLocationwiseSaga,
  getTopProjectsSaga,
  getProjectCountSaga,
  getTopInvestorsSaga,
  getSalesGraphDataSaga,
} from './dashboard/dashboard'
import { update } from 'lodash'
import { getAllTxsSaga } from './txManagement/txManagement'
import { getAllTxsSagaAction } from 'store/sagaActions/txManagement/txManagement'

function* watchAuthentication() {
  yield takeLatest(loginSagaAction.type, loginSaga)
  yield takeLatest(registerSagaAction.type, registerSaga)
  yield takeLatest(getTokenDetailSagaAction.type, tokenDetailSaga)
  yield takeLatest(codeVeriRequestSagaAction.type, codeVerificationRequestSaga)
  yield takeLatest(codeVeriVerifySagaAction.type, codeVerificationVerifySaga)
  yield takeLatest(resetPasswordSagaAction.type, resetPasswordSaga)
  yield takeLatest(logoutSagaAction.type, logoutSaga)
  yield takeLatest(updateSettingUpdateSagaAction.type, updateSettingUpdateSaga)
  yield takeLatest(fetchUserSagaAction.type, fetchUserSaga)
  yield takeLatest(updateUserSagaAction.type, updateUserSaga)
  yield takeLatest(updatePasswordSagaAction.type, updatePasswordSaga)
  yield takeLatest(authOTPVerificationSagaAction.type, authOTPVerificationSaga)
}

function* watchAdmin() {
  yield takeLatest(createAdminSagaAction.type, createAdminSaga)
  yield takeLatest(getAllAdminsSagaAction.type, getAllAdminsSaga)
  yield takeLatest(getAdminSagaAction.type, getAdminSaga)
  yield takeLatest(updateAdminSagaAction.type, updateAdminSaga)
  yield takeLatest(disabledAdminSagaAction.type, disableAdminSaga)
  yield takeLatest(deleteAdminSagaAction.type, deleteAdminSaga)
  yield takeLatest(activityLogsSagaAction.type, getActivityLogsSaga)
  yield takeLatest(getAllPendingInviteSagaAction.type, getAllPendingInvitesSaga)
  yield takeLatest(getPendingInviteSagaAction.type, getPendingInviteSaga)
  yield takeLatest(resendInviteSagaAction.type, resendInviteSaga)
}

function* watchRoles() {
  yield takeLatest(getAllRolesSagaAction.type, getAllRolesSaga)
  yield takeLatest(getRoleSagaAction.type, getRoleSaga)
  yield takeLatest(createRoleSagaAction.type, createRoleSaga)
  yield takeLatest(deleteRoleSagaAction.type, deleteRoleSaga)
  yield takeLatest(updateRoleSagaAction.type, updateRoleSaga)
  yield takeLatest(getAclEndpointSagaAction.type, getAclEndpointsSaga)
}

function* watchUsersManagement() {
  yield takeLatest(getAllUsersSagaAction.type, getAllUsersSaga)
  yield takeLatest(getAllInvestorUsersSagaAction.type, getAllInvestorUsersSaga)
  yield takeLatest(updateUserStatusSagaAction.type, updateUserStatusSaga)
  yield takeLatest(deleteUserSagaAction.type, deleteUserSaga)
  yield takeLatest(getMomentUserSagaAction.type, getMomentUserSaga)
  yield takeLatest(getIdentityeUserSagaAction.type, getIdentityUserSaga)
  yield takeLatest(getExperienceSagaAction.type, getExperienceSaga)
  yield takeLatest(
    getAllUserSpecificTeamsSagaAction.type,
    getAllUserSpecificTeamsSaga
  )
  yield takeLatest(
    getAllUserSpecificExperiencesSagaAction.type,
    getAllUserSpecificExperiencesSaga
  )
  yield takeLatest(reviewExperienceSagaAction.type, reviewExperienceSaga)
  yield takeLatest(getProjectInvestedSagaAction.type, getProjectInvestedSaga)
  yield takeLatest(getProjectListingSagaAction.type, getProjectListingSaga)
  yield takeLatest(
    getInvestedProjectListSagaAction.type,
    getInvestedProjectListSaga
  )
  yield takeLatest(
    requestToReuploadDocSagaAction.type,
    requestToReuploadDocSaga
  )
  yield takeLatest(approveSingleKybDocSagaAction.type, approveSingleKybDocSaga)
  yield takeLatest(submitKybSagaAction.type, submitKybSaga)
  yield takeLatest(approveKybQuestionSagaAction.type, approveKybQuestionSaga)
  yield takeLatest(rejectKybSagaAction.type, rejectKybSaga)
  yield takeLatest(approveKybSagaAction.type, approveKybSaga)
  yield takeLatest(
    requestToUpdateQuestionAnswerSagaAction.type,
    requestToUpdateQuestionAnswerSaga
  )
}

function* watchTxsManagement() {
  yield takeLatest(getAllTxsSagaAction.type, getAllTxsSaga)
}

function* watchExperienceManagement() {
  yield takeLatest(getAllExperiencesSagaAction.type, getAllExperiencesSaga)
  yield takeLatest(
    updateExperienceStatusSagaAction.type,
    updateExperienceStatusSaga
  )
  yield takeLatest(deleteExperienceSagaAction.type, deleteExperienceSaga)
  yield takeLatest(getExperienceSagaAction.type, getExperienceSaga)
  yield takeLatest(getAllTemplateSagaAction.type, getAllTemplateSaga)
  yield takeLatest(createTeplateSagaAction.type, createTemplateSaga)
  yield takeLatest(viewTemplateSagaAction.type, viewTemplateSaga)
  yield takeLatest(deleteTemplateSagaAction.type, deleteTemplateSaga)
  yield takeLatest(getAllCategorySagaAction.type, getAllCategorySaga)
  yield takeLatest(
    updateTemplateStatusSagaAction.type,
    updateTemplateStatusSaga
  )
}

function* watchTeamManagement() {
  yield takeLatest(getTeamSagaAction.type, getTeamSaga)
  yield takeLatest(getAllTeamsSagaAction.type, getAllTeamsSaga)
  yield takeLatest(getTeamMembersSagaAction.type, getTeamMembersSaga)
}

function* watchProjectManagement() {
  yield takeLatest(getAllDraftSagaAction.type, getAllDraftSaga)
  yield takeLatest(getAllPublishedSagaAction.type, getAllPublishedSaga)
  yield takeLatest(getProjectDetailSagaAction.type, getProjectDetailsSaga)
  yield takeLatest(getMessageHistorySagaAction.type, getMessageHistorySaga)
  yield takeLatest(getReplyWithMessageSagaAction.type, getReplyWithMessageSaga)
  yield takeLatest(submitReviewSagaAction.type, submitReviewSaga)
  yield takeLatest(
    fccMintingRequestApproveSagaAction.type,
    fccRequestApproveSaga
  )
  yield takeLatest(fccMintingRequestRejectSagaAction.type, fccRequestRejectSaga)
  yield takeLatest(
    verificationCreditIssuancSagaAction.type,
    verifcationCreditIssuSaga
  )
  yield takeLatest(approveDesignSagaAction.type, approveDesignSaga)
  yield takeLatest(approveDescriptionSagaAction.type, approvedescriptionSaga)
  yield takeLatest(
    approvePublicConsultationSagaAction.type,
    approvePublicCosulationSaga
  )
  yield takeLatest(
    closePublicConsultationSagaAction.type,
    closePublicCosultationSaga
  )
  yield takeLatest(
    approveDescriptionFinalizationSagaAction.type,
    approvedescrptionFinalizationSaga
  )
  yield takeLatest(updateProjectIdSagaAction.type, updateProjectIdSaga)
  yield takeLatest(updateChangeActiveSagaAction.type, updateChangeActiveSaga)

  yield takeLatest(updateRegistryLinkSagaAction.type, updateRegistryLinkSaga)
  yield takeLatest(requestChangeSagaAction.type, requestForChangesSaga)
  yield takeLatest(approveMonitoringSagaAction.type, approveMonitoringPhaseSaga)
  yield takeLatest(getConversationsAction.type, getConversationsDetailsSaga)
  yield takeLatest(getKybQuestionsSagaAction.type, getKybQuestionsSaga)
  yield takeLatest(getVintageYearsSagaAction.type, getVintageYearsSaga)
  yield takeLatest(getMonitoringVccSagaAction.type, getMonitoringVccSaga)

  yield takeLatest(
    getProjectFccHistorySagaAction.type,
    getProjectFccHistorySaga
  )
  yield takeLatest(
    getProjectVccHistorySagaAction.type,
    getProjectVccHistorySaga
  )
  yield takeLatest(
    getProjectVccListingSagaAction.type,
    getProjectVccListingSaga
  )
}

function* watchContentManagent() {
  yield takeLatest(getAllContentSagaAction.type, getAllContentSaga)
  yield takeLatest(UpdateContentSagaAction.type, updateCmsSaga)
}
function* watchPlatformVariable() {
  yield takeLatest(getAllVariableSagaAction.type, getAllVariableSaga)
  yield takeLatest(getAllHistorySagaAction.type, getAllHistorySaga)
  yield takeLatest(updateVariableSagaAction.type, updateVariableSaga)
}

function* watchNotification() {
  yield takeLatest(getAllNotificationSagaAction.type, getAllNotifcationSaga)
  yield takeLatest(markAllNotificationSagaAction.type, markAllNotificationSaga)
  yield takeLatest(
    markSingleNotificationSagaAction.type,
    markSingleNotificationSaga
  )
}

function* watchDashBoard() {
  yield takeLatest(getProjectCountSagaAction.type, getProjectCountSaga)
  yield takeLatest(getLocationWiseProjectSagaAction.type, getLocationwiseSaga)
  yield takeLatest(getTopProjectsAction.type, getTopProjectsSaga)
  yield takeLatest(getTopInvestorsAction.type, getTopInvestorsSaga)
  yield takeLatest(getSalesGraphDataAction.type, getSalesGraphDataSaga)
}

export default function* rootSaga() {
  yield all([
    watchAuthentication(),
    watchAdmin(),
    watchRoles(),
    watchUsersManagement(),
    watchTxsManagement(),
    watchExperienceManagement(),
    watchTeamManagement(),
    watchProjectManagement(),
    watchContentManagent(),
    watchPlatformVariable(),
    watchNotification(),
    watchDashBoard(),
  ])
}
