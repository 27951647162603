import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    login: false,
    register: false,
    logout: false,
    resetPassword: false,
    getTokenDetail: false,
    codeVerificationRequest: false,
    codeVerificationVerify: false,
    updateSetting: false,
    authOTPVerification: false,
  },
  authToken: localStorage.getItem('authToken') || '',
  tokenDetail: {},
  detail: {},
}

const authSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // Login
    loginStart: (state) => {
      state.isLoading.login = true
    },
    loginSuccess: (state, { payload }) => {
      state.isLoading.login = false
      state.authToken = payload?.token
      state.detail = payload
    },
    loginFail: (state, { payload }) => {
      state.isLoading.login = false
    },

    // Get token detail
    getTokenDetailStart: (state) => {
      state.isLoading.getTokenDetail = true
    },
    getTokenDetailSuccess: (state, { payload }) => {
      state.isLoading.getTokenDetail = false
      state.tokenDetail = payload
    },
    getTokenDetailFail: (state, { payload }) => {
      state.isLoading.getTokenDetail = false
    },

    // register
    registerStart: (state) => {
      state.isLoading.register = true
    },
    registerSuccess: (state, { payload }) => {
      state.isLoading.register = false
      state.authToken = payload?.token
    },
    registerFail: (state, { payload }) => {
      state.isLoading.register = false
    },

    //set auth token
    resetAuthToken: (state, { payload }) => {
      state.authToken = payload
    },

    // code verification request
    codeVerificationRequestStart: (state) => {
      state.isLoading.codeVerificationRequest = true
    },
    codeVerificationRequestSuccess: (state, { payload }) => {
      state.isLoading.codeVerificationRequest = false
      state.tokenDetail = payload
    },
    codeVerificationRequestFail: (state, { payload }) => {
      state.isLoading.codeVerificationRequest = false
    },

    // code Verification Verify
    codeVerificationVerifyStart: (state) => {
      state.isLoading.codeVerificationVerify = true
    },
    codeVerificationVerifySuccess: (state, { payload }) => {
      state.isLoading.codeVerificationVerify = false
      state.tokenDetail = payload
    },
    codeVerificationVerifyFail: (state, { payload }) => {
      state.isLoading.codeVerificationVerify = false
    },

    // Reset password
    resetPasswordStart: (state) => {
      state.isLoading.resetPassword = true
    },
    resetPasswordSuccess: (state, { payload }) => {
      state.isLoading.resetPassword = false
      state.authToken = payload?.token
    },
    resetPasswordFail: (state, { payload }) => {
      state.isLoading.resetPassword = false
    },

    //logout
    logoutStart: (state) => {
      state.isLoading.logout = true
    },
    logoutSuccess: (state) => {
      state.isLoading.logout = false
      state.authToken = ''
    },
    logoutFail: (state) => {
      state.isLoading.logout = false
    },

    // update setting
    updateSettingStart: (state) => {
      state.isLoading.updateSetting = true
    },
    updateSettingSuccess: (state) => {
      state.isLoading.updateSetting = false
    },
    updateSettingFail: (state) => {
      state.isLoading.updateSetting = false
    },

    // Google auth app OTP Verification
    authOTPVerificationStart: (state) => {
      state.isLoading.authOTPVerification = true
    },
    authOTPVerificationSuccess: (state, { payload }) => {
      state.isLoading.authOTPVerification = false
      state.authToken = payload?.authToken
    },
    authOTPVerificationFail: (state) => {
      state.isLoading.authOTPVerification = false
    },
  },
})

export const {
  loginStart,
  loginSuccess,
  loginFail,
  logoutStart,
  logoutSuccess,
  logoutFail,
  getTokenDetailStart,
  getTokenDetailSuccess,
  getTokenDetailFail,
  registerStart,
  registerSuccess,
  registerFail,
  codeVerificationRequestStart,
  codeVerificationRequestSuccess,
  codeVerificationRequestFail,
  codeVerificationVerifyStart,
  codeVerificationVerifySuccess,
  codeVerificationVerifyFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  updateSettingStart,
  updateSettingSuccess,
  updateSettingFail,
  resetAuthToken,
  authOTPVerificationStart,
  authOTPVerificationSuccess,
  authOTPVerificationFail,
} = authSlice.actions

export const authReducer = authSlice.reducer
