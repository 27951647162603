/* eslint-disable no-undef */
import axios from 'axios'

const axiosMain = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

export default axiosMain
