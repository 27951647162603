import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: {
    getAllUsers: false,
    getAllInvestorUsers: false,
    updateUserStatus: false,
    deleteUser: false,
    getMomentUser: false,
    getIdentityUser: false,
    getProjectDetailUser: false,
    getExperience: false,
    getAllUserSpecificTeams: false,
    getAllUserSpecificExperiences: false,
    reviewExperience: false,
    getProjectListing: false,
    getInvestorProjectListing: false,
  },
  allUsers: {},
  allUsersData: {},
  allInvestorUser: {},
  allInvestorUserData: {},
  getUser: {},
  projectDetails: {},
  allUserSpecificTeams: {},
  allUserSpecificExperiences: {},
  experience: {},
  updateExperienceMessage: '',
  reviewExperienceMessage: '',
  projectListing: null,
  investorProjectList: null,
}
const initialInvestorState = {
  isLoading: {
    getAllInvestorUsers: false,
    updateUserStatus: false,
    deleteUser: false,
    getMomentUser: false,
    getIdentityUser: false,
    getExperience: false,
    getAllUserSpecificTeams: false,
    getAllUserSpecificExperiences: false,
    reviewExperience: false,
    requestToReuploadDoc: false,
    approveSingleKybDoc: false,
    rejectKyb: false,
    approveKyb: false,
    requestToUpdateQuestionAnswer: false,
  },
  allInvestorUser: {},
  getUser: {},
  projectDetails: {},
  allUserSpecificTeams: {},
  allUserSpecificExperiences: {},
  experience: {},
  updateExperienceMessage: '',
  reviewExperienceMessage: '',
  requestToReuploadDoc: {},
  approveSingleKybDoc: {},
  rejectKyb: {},
  approveKyb: {},
  requestToUpdateQuestionAnswer: {},
}

export const userManagementSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Get All Users
    getAllUsersStart: (state) => {
      // console.log(state, 'state isloading')
      state.isLoading.getAllUsers = true
    },

    getAllUsersSuccess: (state, { payload }) => {
      state.isLoading.getAllUsers = false
      state.allUsers = payload.data
      state.allUsersData = payload
    },
    getAllUsersFail: (state) => {
      state.isLoading.getAllUsers = false
    },

    // get All investor User
    getAllInvestorUserStart: (state) => {
      state.isLoading.getAllInvestorUsers = true
    },
    getAllInvestorUsersSuccess: (state, { payload }) => {
      state.isLoading.getAllInvestorUsers = false
      state.allInvestorUser = payload.data
      state.allInvestorUserData = payload
    },
    getAllInvestorUsersFail: (state) => {
      state.isLoading.getAllInvestorUsers = false
    },

    // Uptate User Status
    updateUserStatusStart: (state) => {
      state.isLoading.updateUserStatus = true
    },
    updateUserStatusSuccess: (state, { payload }) => {
      state.isLoading.updateUserStatus = false
      if (state?.allUsers?.items?.length) {
        const userIndex = state.allUsers.items.findIndex(
          (item) => item._id === payload.id
        )
        state.allUsers.items[userIndex].accountMetadata.isBlockedByAdmin =
          payload.status
      }
      state.updateExperienceMessage = payload.message
    },
    updateUserStatusFail: (state) => {
      state.isLoading.updateUserStatus = false
    },

    // Delete User
    deleteUserStart: (state) => {
      state.isLoading.deleteUser = true
    },
    deleteUserSuccess: (state) => {
      state.isLoading.deleteUser = false
    },
    deleteUserFail: (state) => {
      state.isLoading.deleteUser = false
    },

    // Get Moment User Data
    getMomentUserStart: (state) => {
      state.isLoading.getMomentUser = true
    },
    getMomentUserSuccess: (state, { payload }) => {
      state.isLoading = false
      state.getUser = payload
    },
    getMomentUserFail: (state) => {
      state.isLoading.getMomentUser = false
    },

    // Get Identity User Data
    getIdentityUserStart: (state) => {
      state.isLoading.getIdentityUser = true
    },
    getIdentityUserSuccess: (state, { payload }) => {
      state.isLoading.getIdentityUser = false
      state.getUser = payload
    },
    getIdentityUserFail: (state) => {
      state.isLoading.getIdentityUser = false
    },

    // Get All User Specific Teams
    getAllUserSpecificTeamsStart: (state) => {
      state.isLoading.getAllUserSpecificTeams = true
    },
    getAllUserSpecificTeamsSuccess: (state, { payload }) => {
      state.isLoading.getAllUserSpecificTeams = false
      state.allUserSpecificTeams = payload
    },
    getAllUserSpecificTeamsFail: (state) => {
      state.isLoading.getAllUserSpecificTeams = true
    },

    // Get All User Specific Experiences
    getAllUserSpecificExperiencesStart: (state) => {
      state.isLoading.getAllUserSpecificExperiences = true
    },
    getAllUserSpecificExperiencesSuccess: (state, { payload }) => {
      state.isLoading.getAllUserSpecificExperiences = false
      state.allUserSpecificExperiences = payload
    },
    getAllUserSpecificExperiencesFail: (state) => {
      state.isLoading.getAllUserSpecificExperiences = true
    },

    // Review Experience
    reviewExperienceStart: (state) => {
      state.isLoading.reviewExperience = true
    },
    reviewExperienceSuccess: (state, { payload }) => {
      state.reviewExperienceMessage = payload
    },
    reviewExperienceFail: (state) => {
      state.isLoading.reviewExperience = false
    },

    // Get Experience Data
    getExperienceStart: (state) => {
      state.isLoading.getExperience = true
    },
    getExperienceSuccess: (state, { payload }) => {
      state.isLoading.getExperience = false
      state.experience = payload
    },
    getExperienceFail: (state) => {
      state.isLoading.getExperience = false
    },

    // Get Project Listing for specific user
    getProjectListingStart: (state) => {
      state.isLoading.getProjectListing = true
    },
    getProjectListingSuccess: (state, { payload }) => {
      state.isLoading.getProjectListing = false
      state.projectListing = payload
    },
    getProjectListingFail: (state) => {
      state.isLoading.getProjectListing = false
    },

    // Get Project Listing for Investor
    getInvestedProjectListStart: (state) => {
      state.isLoading.getInvestorProjectListing = true
    },
    getInvestedProjectListSuccess: (state, { payload }) => {
      state.isLoading.getInvestorProjectListing = false
      state.investorProjectList = payload
    },
    getInvestedProjectListFail: (state) => {
      state.isLoading.getInvestorProjectListing = false
    },
    // requestToReuploadDoc Status
    requestToReuploadDocStart: (state) => {
      state.isLoading.requestToReuploadDoc = true
    },
    requestToReuploadDocSuccess: (state, { payload }) => {
      state.isLoading.requestToReuploadDoc = false
      state.requestToReuploadDoc = payload.message
    },
    requestToReuploadDocFail: (state) => {
      state.isLoading.requestToReuploadDoc = false
    },

    // approveSingleKybDoc Status
    approveSingleKybDocStart: (state) => {
      state.isLoading.approveSingleKybDoc = true
    },
    approveSingleKybDocSuccess: (state, { payload }) => {
      state.isLoading.approveSingleKybDoc = false
      state.approveSingleKybDoc = payload.message
    },
    approveSingleKybDocFail: (state) => {
      state.isLoading.approveSingleKybDoc = false
    },

    // submitKybDoc Status
    submitKybStart: (state) => {
      state.isLoading.submitKybDoc = true
    },
    submitKybSuccess: (state, { payload }) => {
      state.isLoading.submitKybDoc = false
      state.submitKybDoc = payload.message
    },
    submitKybFail: (state) => {
      state.isLoading.submitKybDoc = false
    },

    // approveKybQuestion Status
    approveKybQuestionStart: (state) => {
      state.isLoading.approveKybQuestion = true
    },
    approveKybQuestionSuccess: (state, { payload }) => {
      state.isLoading.approveKybQuestion = false
      state.approveKybQuestion = payload.message
    },
    approveKybQuestionFail: (state) => {
      state.isLoading.approveKybQuestion = false
    },

    // rejectKyb Status
    rejectKybStart: (state) => {
      state.isLoading.rejectKyb = true
    },
    rejectKybSuccess: (state, { payload }) => {
      state.isLoading.rejectKyb = false
      state.rejectKyb = payload.message
    },
    rejectKybFail: (state) => {
      state.isLoading.rejectKyb = false
    },
    // approveKyb Status
    approveKybStart: (state) => {
      state.isLoading.approveKyb = true
    },
    approveKybSuccess: (state, { payload }) => {
      state.isLoading.approveKyb = false
      state.approveKyb = payload.message
    },
    approveKybFail: (state) => {
      state.isLoading.approveKyb = false
    },
    // requestToUpdateQuestionAnswer Status
    requestToUpdateQuestionAnswerStart: (state) => {
      state.isLoading.requestToUpdateQuestionAnswer = true
    },
    requestToUpdateQuestionAnswerSuccess: (state, { payload }) => {
      state.isLoading.requestToUpdateQuestionAnswer = false
      state.requestToUpdateQuestionAnswer = payload.message
    },
    requestToUpdateQuestionAnswerFail: (state) => {
      state.isLoading.requestToUpdateQuestionAnswer = false
    },
  },
})
export const userManagementInvestorSlice = createSlice({
  name: 'userInvestor',
  initialInvestorState,
  reducers: {
    // get All investor User
    getAllInvestorUserStart: (state) => {
      state.isLoading.getAllInvestorUsers = true
    },
    getAllInvestorUsersSuccess: (state, { payload }) => {
      state.isLoading.getAllInvestorUsers = false
      state.allInvestorUser = payload
    },
    getAllInvestorUsersFail: (state) => {
      state.isLoading.getAllInvestorUsers = false
    },

    // Uptate User Status
    updateUserStatusStart: (state) => {
      state.isLoading.updateUserStatus = true
    },
    updateUserStatusSuccess: (state, { payload }) => {
      state.isLoading.updateUserStatus = false
      if (state?.allInvestorUser?.items?.length) {
        const userIndex = state.allInvestorUser.items.findIndex(
          (item) => item._id === payload.id
        )
        state.allInvestorUser.items[
          userIndex
        ].accountMetadata.isBlockedByAdmin = payload.status
      }
      state.updateExperienceMessage = payload.message
    },
    updateUserStatusFail: (state) => {
      state.isLoading.updateUserStatus = false
    },

    // Delete User
    deleteUserStart: (state) => {
      state.isLoading.deleteUser = true
    },
    deleteUserSuccess: (state) => {
      state.isLoading.deleteUser = false
    },
    deleteUserFail: (state) => {
      state.isLoading.deleteUser = false
    },

    // Get Moment User Data
    getMomentUserStart: (state) => {
      state.isLoading.getMomentUser = true
    },
    getMomentUserSuccess: (state, { payload }) => {
      state.isLoading = false
      state.getUser = payload
    },
    getMomentUserFail: (state) => {
      state.isLoading.getMomentUser = false
    },

    // Get Identity User Data
    getIdentityUserStart: (state) => {
      state.isLoading.getIdentityUser = true
    },
    getIdentityUserSuccess: (state, { payload }) => {
      state.isLoading.getIdentityUser = false
      state.getUser = payload
    },
    getIdentityUserFail: (state) => {
      state.isLoading.getIdentityUser = false
    },

    // Get All User Specific Teams
    getAllUserSpecificTeamsStart: (state) => {
      state.isLoading.getAllUserSpecificTeams = true
    },
    getAllUserSpecificTeamsSuccess: (state, { payload }) => {
      state.isLoading.getAllUserSpecificTeams = false
      state.allUserSpecificTeams = payload
    },
    getAllUserSpecificTeamsFail: (state) => {
      state.isLoading.getAllUserSpecificTeams = true
    },

    // Get All User Specific Experiences
    getAllUserSpecificExperiencesStart: (state) => {
      state.isLoading.getAllUserSpecificExperiences = true
    },
    getAllUserSpecificExperiencesSuccess: (state, { payload }) => {
      state.isLoading.getAllUserSpecificExperiences = false
      state.allUserSpecificExperiences = payload
    },
    getAllUserSpecificExperiencesFail: (state) => {
      state.isLoading.getAllUserSpecificExperiences = true
    },

    // Review Experience
    reviewExperienceStart: (state) => {
      state.isLoading.reviewExperience = true
    },
    reviewExperienceSuccess: (state, { payload }) => {
      state.reviewExperienceMessage = payload
    },
    reviewExperienceFail: (state) => {
      state.isLoading.reviewExperience = false
    },

    // Get Experience Data
    getExperienceStart: (state) => {
      state.isLoading.getExperience = true
    },
    getExperienceSuccess: (state, { payload }) => {
      state.isLoading.getExperience = false
      state.experience = payload
    },
    getExperienceFail: (state) => {
      state.isLoading.getExperience = false
    },

    // Get Project Listing for investor
    getProjectInvestedStart: (state) => {
      state.isLoading.projectInvested = true
    },
    getProjectInvestedSuccess: (state, { payload }) => {
      state.isLoading.projectInvested = false
      state.projectInvested = payload
    },
    getProjectInvestedFail: (state) => {
      state.isLoading.projectInvested = false
    },
  },
})

export default userManagementSlice.reducer
export const {
  getAllUsersStart,
  getAllUsersSuccess,
  getAllUsersFail,
  getAllInvestorUserStart,
  getAllInvestorUsersSuccess,
  getAllInvestorUsersFail,
  updateUserStatusStart,
  updateUserStatusSuccess,
  updateUserStatusFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  getMomentUserStart,
  getMomentUserSuccess,
  getMomentUserFail,
  getIdentityUserStart,
  getIdentityUserSuccess,
  getIdentityUserFail,
  getAllUserSpecificTeamsStart,
  getAllUserSpecificTeamsSuccess,
  getAllUserSpecificTeamsFail,
  getAllUserSpecificExperiencesStart,
  getAllUserSpecificExperiencesSuccess,
  getAllUserSpecificExperiencesFail,
  reviewExperienceStart,
  reviewExperienceSuccess,
  reviewExperienceFail,
  getExperienceStart,
  getExperienceSuccess,
  getExperienceFail,
  getProjectListingStart,
  getProjectListingSuccess,
  getProjectListingFail,
  getInvestedProjectListStart,
  getInvestedProjectListSuccess,
  getInvestedProjectListFail,
  getProjectInvestedStart,
  getProjectInvestedSuccess,
  getProjectInvestedFail,
  requestToReuploadDocStart,
  requestToReuploadDocSuccess,
  requestToReuploadDocFail,
  approveSingleKybDocStart,
  approveSingleKybDocSuccess,
  approveSingleKybDocFail,
  submitKybStart,
  submitKybSuccess,
  submitKybFail,
  rejectKybStart,
  rejectKybSuccess,
  rejectKybFail,
  approveKybStart,
  approveKybSuccess,
  approveKybFail,
  approveKybQuestionStart,
  approveKybQuestionSuccess,
  approveKybQuestionFail,
  requestToUpdateQuestionAnswerStart,
  requestToUpdateQuestionAnswerSuccess,
  requestToUpdateQuestionAnswerFail,
} = userManagementSlice.actions
