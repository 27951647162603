/* eslint-disable no-lonely-if */
import { put } from 'redux-saga/effects'
// import { logoutSagaAction } from 'store/sagaActions/auth/auth'
import axiosMain from './axiosMain'
import { USER_SUBDOMIN } from 'constants/envs.constant'

const createAPIUrl = (baseURL, endpoint) => {
  if (baseURL) return `${baseURL}${endpoint}`
  return `${USER_SUBDOMIN}/api/v1${endpoint}`

  //`https://92b0-2401-4900-1f3c-48ed-3dd5-b6e-c422-4911.ngrok-free.app/api/v1${endpoint}`
}

export default function* errorHandler({
  endpoint,
  successHandler,
  failHandler,
  failHandlerType = '',
  payload = {},
  apiType = '',
  token = false,
  // isLogoutCall = false,
  baseURL = '',
  params = {},
  // showToast = "",
}) {
  if (apiType.trim() === '') {
    throw new Error('apiType is require')
  }
  try {
    let response
    if (!token) {
      if (apiType === 'get') {
        response = yield axiosMain.get(createAPIUrl(baseURL, endpoint))
      } else if (apiType === 'post') {
        response = yield axiosMain.post(
          createAPIUrl(baseURL, endpoint),
          payload
        )
      } else if (apiType === 'put') {
        response = yield axiosMain.put(createAPIUrl(baseURL, endpoint), payload)
      } else if (apiType === 'delete') {
        response = yield axiosMain.delete(createAPIUrl(baseURL, endpoint))
      } else if (apiType === 'patch') {
        response = yield axiosMain.patch(
          createAPIUrl(baseURL, endpoint),
          payload
        )
      }
    } else {
      const authToken = yield localStorage.getItem('authToken')
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'ngrok-skip-browser-warning': '69420',
        },
        params,
      }
      // eslint-disable-next-line no-lonely-if
      if (apiType === 'get') {
        response = yield axiosMain.get(createAPIUrl(baseURL, endpoint), config)
      } else if (apiType === 'post') {
        response = yield axiosMain.post(
          createAPIUrl(baseURL, endpoint),
          payload,
          config
        )
      } else if (apiType === 'put') {
        response = yield axiosMain.put(
          createAPIUrl(baseURL, endpoint),
          payload,
          config
        )
      } else if (apiType === 'delete') {
        response = yield axiosMain.delete(
          createAPIUrl(baseURL, endpoint),
          config
        )
      } else if (apiType === 'patch') {
        response = yield axiosMain.patch(
          createAPIUrl(baseURL, endpoint),
          payload,
          config
        )
      }
    }
    if (
      response &&
      (response.status === 200 || response.status === 201) &&
      response.data
    ) {
      yield successHandler(response.data)
      // showToast && successToast(response.data);
    } else if (response !== undefined && response.status !== undefined) {
      if (
        response.data.error !== undefined &&
        response.data.error !== '' &&
        typeof response.data.error === 'string'
      ) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(response.data.msg, response.data)
        } else {
          yield put(failHandler(response.data.msg))
        }
      } else {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(response.data.error)
        } else {
          yield put(failHandler('Server error! Please try again.'))
        }
      }
    } else {
      if (failHandlerType === 'CUSTOM') {
        yield failHandler('Something went wrong! Please try again.')
      } else {
        yield put(failHandler('Something went wrong! Please try again.'))
      }
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error?.response?.data?.error)
        } else {
          yield put(failHandler(error.response?.data?.error))
        }
      } else if (error.response.status === 401) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler('')
          // yield put(
          //   logoutSagaAction({
          //     redirect: !!token,
          //     error: error?.response?.data?.error?.message,
          //   })
          // )
        } else {
          yield put(failHandler(error.response.data.msg))
        }
      } else if (
        error.response.data.error !== undefined &&
        error.response.data.error !== '' &&
        typeof error.response.data.error === 'string'
      ) {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler(error.response.data.error)
        } else {
          yield put(failHandler(error.response.data.error))
        }
      } else {
        if (failHandlerType === 'CUSTOM') {
          yield failHandler('Server error! Please try again.')
        } else {
          yield put(failHandler('Server error! Please try again.'))
        }
      }
    } else {
      if (failHandlerType === 'CUSTOM') {
        yield failHandler('Something went wrong! Please try again.')
      } else {
        yield put(failHandler('Something went wrong! Please try again.'))
      }
    }
  }
}
