import { put } from 'redux-saga/effects'
import errorHandler from 'utils/errorHandler'
import { BASE_URL } from 'constants/envs.constant'
import {
  getAllTxsFail,
  getAllTxsStart,
  getAllTxsSuccess,
} from 'store/reducer/txManagement'

// Get All Users
export function* getAllTxsSaga(action) {
  yield put(getAllTxsStart())
  const { startIndex, search, startDate, endDate, itemsPerPage } =
    action.payload
  yield errorHandler({
    baseURL: BASE_URL,
    endpoint: `/admin/transaction?txnType=buy`,
    successHandler: yield function* (response) {
      yield put(getAllTxsSuccess(response))
      // onSuccess?.(response)
    },
    failHandler: yield function* (response) {
      yield put(getAllTxsFail(response))
    },
    failHandlerType: 'CUSTOM',
    token: true,
    apiType: 'get',
    params: {
      search,
      startIndex,
      itemsPerPage,
      startDate,
      endDate,
    },
  })
}
