import { createAction } from '@reduxjs/toolkit'

export const getAllDraftSagaAction = createAction('GET_All_DRAFT_SAGA')
export const getAllPublishedSagaAction = createAction('GET_ALL_PUBLISHED_SAGA')
export const getProjectDetailSagaAction = createAction(
  'GET_PROJECT_DETAIL_SAGA'
)
export const getMessageHistorySagaAction = createAction(
  'GET_MESSAGE_HISTORY_SAGA'
)
export const getProjectFccHistorySagaAction = createAction(
  'GET_PROJECT_FCC_HISTORY_SAGA'
)
export const getProjectVccHistorySagaAction = createAction(
  'GET_PROJECT_VCC_HISTORY_SAGA'
)
export const getProjectVccListingSagaAction = createAction(
  'GET_PROJECT_VCC_LISTING_SAGA'
)
export const getReplyWithMessageSagaAction = createAction(
  'GET_REPLY_WITH_MESSAGE_SAGA'
)
export const submitReviewSagaAction = createAction('SUBMIT_REVIEW_SAGA')
export const fccMintingRequestApproveSagaAction = createAction(
  'FCC_MINTING_REQUEST_APPROVE'
)
export const fccMintingRequestRejectSagaAction = createAction(
  'FCC_MINTING_REQUEST_REJECT'
)
export const verificationCreditIssuancSagaAction = createAction(
  'VERIFICATION_CREDIT_ISSUANC'
)
export const approveDesignSagaAction = createAction('APPROVE_DESIGN')
export const approveDescriptionSagaAction = createAction('APPROVE_DESCRIPTION')
export const approvePublicConsultationSagaAction = createAction(
  'APPROVE_PUBLIC_CONSULATION'
)
export const closePublicConsultationSagaAction = createAction(
  'CLOSE_PUBLIC_CONSULATION'
)
export const approveDescriptionFinalizationSagaAction = createAction(
  'APPROVE_DESCRIPTION_FINAL'
)
export const requestChangeSagaAction = createAction('REQUEST_CHANGE')
export const approveMonitoringSagaAction = createAction('APPROVE_MONITORING')
export const getConversationsAction = createAction('GET_CONVERSATIONS')

export const updateProjectIdSagaAction = createAction('UPDATE_PROJECT_ID_SAGA')
export const updateChangeActiveSagaAction = createAction(
  'UPDATE_CHANGE_ACTIVE_SAGA'
)

export const updateRegistryLinkSagaAction = createAction(
  'UPDATE_REGISTRY_LINK_SAGA'
)
export const selectVintageYearAction = (year) => {
  return {
    type: 'SELECT_VINTAGE_YEAR',
    payload: year,
  }
}
